import axios from 'axios';
import { assign, map, keyBy } from 'lodash';
import Vue from 'vue';

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
  fetchProvinceReportStatus(
    { state, commit, rootState, rootGetters },
    { nationalPlan, province }
  ) {
    return axios
      .get(
        '/api/national-plans/' +
          nationalPlan.id +
          '/province/' +
          province.id +
          '/report_status'
      )
      .then(({ data }) => {
        return data;
      });
  },
  fetchProvinceReports(
    { state, commit, rootState, rootGetters },
    { nationalPlan, province }
  ) {
    return axios
      .get(
        '/api/national-plans/' +
          nationalPlan.id +
          '/province/' +
          province.id +
          '/reports'
      )
      .then(({ data }) => {
        return data.data;
      });
  },
  fetchProvinceReport({ state, commit, rootState, rootGetters }, province) {
    return axios
      .get('/api/provinces/' + province.id + '/report')
      .then(({ data }) => {
        return data.data;
      });
  },
  fetchProvinceReportEvaluations(
    { state, commit, rootState },
    { year, province }
  ) {
    return axios
      .get(
        '/api/province/' + province.id + '/year/' + year + '/report_evaluations'
      )
      .then(({ data }) => {
        return data;
      });
  },
};
