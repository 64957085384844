import axios from 'axios';
import {assign, map, keyBy} from 'lodash';
import Vue from 'vue';

export const state = {
  currentProvince: null,
  provinceSummaries: {},
  nationalPlanOverviews: {},
};

export const getters = {};

export const mutations = {
  setCurrentProvince(state, province) {
    state.currentProvince = province;
  },
  setNationalPlanOverview(state, nationalPlanOverview) {
    state.nationalPlanOverviews[nationalPlanOverview.id] = nationalPlanOverview;
  },
  setProvinceSummary(state, {summary, province}) {
    state.provinceSummaries[province.id] = summary;
  },
};

export const actions = {
  fetchNationalPlanOverview(
    {state, commit, rootState, rootGetters},
    nationalPlan
  ) {
    return axios
      .get('/api/national-plans/' + nationalPlan.id + '/overview')
      .then(({data}) => {
        commit('setNationalPlanOverview', data);
        return data;
      });
  },
  fetchProvinceSummary(
    {state, commit, rootState, rootGetters},
    {nationalPlan, province}
  ) {
    return axios
      .get(
        '/api/national-plans/' +
        nationalPlan.id +
        '/province/' +
        province.id +
        '/summary'
      )
      .then(({data}) => {
        commit('setProvinceSummary', {
          summary: data,
          province: province,
        });
        return data;
      });
  },
  reverseReportVersion(
    {state, commit, rootState, rootGetters},
    {nationalPlan, province}
  ) {
    return axios
      .patch(
        '/api/national-plans/' +
        nationalPlan.id +
        '/province/' +
        province.id +
        '/reverse_version'
      )
      .then(({data}) => {
        return data;
      });
  },
};
