const Dashboard = () => import('../views/layouts/PenoDashboard.vue');
const Index = () => import('../views/peno/PenoIndex.vue');
const ReportDashboard = () => import('../views/peno/PenoReportDashboard.vue');
const OverviewDashboard = () =>
  import('../views/peno/PenoOverviewDashboard.vue');
const Intro = () => import('../views/peno/Intro.vue');
const ProvinceEnergyData = () => import('../views/peno/ProvinceEnergyData.vue');
const OtherProvinceReport = () =>
  import('../views/peno/OtherProvinceReport.vue');

const PlanRelation = () => import('../views/peno/report/PlanRelation.vue');
const CurrentSituation = () =>
  import('../views/peno/report/CurrentSituation.vue');
const EnergyDiagram = () =>
  import('../views/peno/report/current_situation/EnergyDiagram.vue');
const EnergyUsage = () =>
  import('../views/peno/report/current_situation/EnergyUsage.vue');
const EnergyCapability = () =>
  import('../views/peno/report/current_situation/EnergyCapability.vue');
const Infrastructure = () =>
  import('../views/peno/report/current_situation/Infrastructure.vue');
const Metric = () =>
  import('../views/peno/report/current_situation/Metric.vue');
const Scenarios = () => import('../views/peno/report/Scenarios.vue');
const ScenariosOverview = () =>
  import('../views/peno/report/scenarios/ScenariosOverview.vue');
const DemandCaseDetail = () =>
  import('../views/peno/report/scenarios/DemandCaseDetail.vue');
const ScenarioDetail = () =>
  import('../views/peno/report/scenarios/ScenarioDetail.vue');
const Targets = () => import('../views/peno/report/Targets.vue');
const Swot = () => import('../views/peno/report/Swot.vue');
const Projects = () => import('../views/peno/report/Projects.vue');
const Summary = () => import('../views/peno/report/Summary.vue');
const Appendix = () => import('../views/peno/report/Appendix.vue');
const Preview = () => import('../views/peno/report/Preview.vue');
const Announcement = () => import('../views/peno/Announcement.vue');
const PreviousYearProject = () =>
  import('../views/peno/PreviousYearProject.vue');

export default [
  {
    path: '/peno',
    component: Dashboard,
    meta: {
      allowRoles: ['peno'],
    },
    children: [
      {
        path: 'index',
        name: 'peno.index',
        component: Index,
      },
      {
        path: 'overview',
        name: 'peno.overview',
        component: OverviewDashboard,
        children: [
          {
            path: 'intro',
            name: 'peno.overview.intro',
            component: Intro,
            meta: {
              authType: 'peno',
            },
          },
          {
            path: 'province-energy',
            name: 'peno.overview.province_energy',
            component: ProvinceEnergyData,
            meta: {
              authType: 'peno',
            },
          },
        ],
      },
      {
        path: 'announcement',
        name: 'peno.announcement',
        component: Announcement,
        meta: {
          authType: 'peno',
        },
      },
      {
        path: 'previous-year-projects',
        name: 'peno.previous_projects',
        component: PreviousYearProject,
        meta: {
          authType: 'peno',
        },
      },
      {
        path: 'report',
        component: ReportDashboard,
        children: [
          {
            path: 'relation',
            name: 'peno.plan_relation',
            component: PlanRelation,
            meta: {
              authType: 'peno',
            },
          },
          {
            path: 'current_situation',
            name: 'peno.current_situation',
            component: CurrentSituation,
            meta: {
              authType: 'peno',
            },
            children: [
              {
                path: 'energy_diagram',
                name: 'peno.current_situation.energy_diagram',
                component: EnergyDiagram,
                meta: {
                  authType: 'peno',
                },
              },
              {
                path: 'energy_usage',
                name: 'peno.current_situation.energy_usage',
                component: EnergyUsage,
                meta: {
                  authType: 'peno',
                },
              },
              {
                path: 'energy_capability',
                name: 'peno.current_situation.energy_capability',
                component: EnergyCapability,
                meta: {
                  authType: 'peno',
                },
              },
              {
                path: 'infrastructure',
                name: 'peno.current_situation.infrastructure',
                component: Infrastructure,
                meta: {
                  authType: 'peno',
                },
              },
              {
                path: 'metric',
                name: 'peno.current_situation.metric',
                component: Metric,
                meta: {
                  authType: 'peno',
                },
              },
            ],
          },
          {
            path: 'scenarios',
            // name: 'peno.scenarios',
            component: Scenarios,
            meta: {
              authType: 'peno',
            },
            children: [
              {
                path: '',
                name: 'peno.scenarios',
                component: ScenariosOverview,
                meta: {
                  authType: 'peno',
                },
              },
              {
                path: 'demand-case/:type',
                name: 'peno.scenarios.demand_case_detail',
                component: DemandCaseDetail,
                meta: {
                  authType: 'peno',
                },
              },
              {
                path: 'scenarios-detail/:scenario',
                name: 'peno.scenarios.scenario_detail',
                component: ScenarioDetail,
                meta: {
                  authType: 'peno',
                },
              },
            ],
          },
          {
            path: 'targets',
            name: 'peno.targets',
            component: Targets,
            meta: {
              authType: 'peno',
            },
          },
          {
            path: 'swot',
            name: 'peno.swot',
            component: Swot,
            meta: {
              authType: 'peno',
            },
          },
          {
            path: 'projects',
            name: 'peno.projects',
            component: Projects,
            meta: {
              authType: 'peno',
            },
          },
          {
            path: 'summary',
            name: 'peno.summary',
            component: Summary,
            meta: {
              authType: 'peno',
            },
          },
          {
            path: 'appendix',
            name: 'peno.appendix',
            component: Appendix,
            meta: {
              authType: 'peno',
            },
          },
          {
            path: 'preview',
            name: 'peno.preview',
            component: Preview,
          },
        ],
      },
      {
        path: ':iso3166_2/report',
        name: 'peno.other',
        component: OtherProvinceReport,
      },
    ],
  },
];
