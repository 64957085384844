import Vue from 'vue';

let uidCounter = 0;
Vue.mixin({
  beforeCreate() {
    uidCounter += 1;
    const uid = `uid-${uidCounter}`;
    Object.defineProperties(this, {
      uid: {
        get() {
          return uid;
        },
      },
    });
  },
});
