<template>
  <div>
    <div
      class="
        login-box
        bg-white
        border
        rounded
        border-light
        text-center
        p-3 p-md-0
      "
    >
      <h1 class="mt-4 font-weight-bold">
        {{ $t('login.welcome') }}
      </h1>
      <h5 class="">{{ $t('login.please_signin') }}</h5>
      <h5 class="text-red font-weight-bold">
        {{ $t('login.do_energy_provincial_plan') }}
      </h5>
      <form
        action=""
        v-promise-btn="{ action: 'submit' }"
        @submit.prevent="submit"
        class="mb-5"
      >
        <div class="form-group row justify-content-center">
          <!--<div class="alert alert-danger" role="alert">-->
          <!--This is a danger alert—check it out!-->
          <!--</div>-->
          <div class="col-sm-10">
            <input
              type="email"
              name="email"
              placeholder="email"
              v-model="credentials.email"
              class="
                form-control
                text-center
                border-top-0 border-left-0 border-right-0
                login-border-light
              "
            />
            <div v-show="credentials.errors['email']" class="text-danger">
              {{ credentials.errors['email'] }}
            </div>
          </div>
          <div class="col-sm-10 mt-4">
            <input
              type="password"
              name="password"
              placeholder="password"
              v-model="credentials.password"
              class="
                form-control
                text-center
                border-top-0 border-left-0 border-right-0
                login-border-light
              "
            />
            <div v-show="credentials.errors['password']" class="text-danger">
              {{ credentials.errors['password'] }}
            </div>
          </div>
          <div class="mt-2">
            <input
              type="checkbox"
              id="remember_me"
              name="remember"
              v-model="credentials.remember"
            />&nbsp;<label for="remember_me">คงสถานะการเข้าสู่ระบบ</label>
          </div>
          <div class="col-sm-10 helper mt-3">
            **ใช้ Username และ Password เดียวกันกับระบบ Intranet
            ของกระทรวงพลังงาน
          </div>
          <div class="col-sm-10 helper mt-2">
            <div class="d-flex justify-content-between">
              <button
                type="button"
                class="btn btn-success px-3 btn-login"
                @click="loginWithGovID"
                v-promise-btn
              >
                เข้าสู่ระบบด้วย mail.go.th
              </button>
              <button type="submit" class="btn btn-action px-3 btn-login">
                {{ $t('เข้าสู่ระบบ') }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import Form from '../modules/form/form';
import { required, email } from 'vuelidate/lib/validators';
import { get } from 'lodash';

export default {
  name: 'login',
  data() {
    return {
      errorMessage: null,
      credentials: new Form({
        data() {
          return {
            email: '',
            password: '',
            remember: false,
          };
        },
        validations: {
          email: {
            required,
            email,
          },
          password: {
            required,
          },
        },
      }),
      /* loading: false,
      email: '',
      password: '' */
    };
  },
  created() {},
  computed: {
    ...mapGetters(['govIdCallbackUrl']),
    /* postParams() {
      return {
        email: this.email,
        password: this.password
      };
    } */
  },
  methods: {
    ...mapMutations('auth', ['login']),
    ...mapMutations(['showLoading', 'setCsrfToken']),
    init(data) {
      // this.setCsrfToken(data.csrfToken);
    },
    loginWithGovID() {
      this.errorMessage = null;
      const callback_url = this.govIdCallbackUrl;
      return window.axios
        .post('/api/govid/redirect', {
          callback_url: callback_url,
        })
        .then(({ data }) => {
          if (data.redirect_url) {
            window.location = data.redirect_url;
          }
        })
        .catch((error) => {
          const errorMessage = get(error, 'response.data.error', '');
          this.$toasted.global.display_error({
            message: errorMessage,
          });
        });
    },
    submit() {
      return this.credentials
        .post('api/authenticate')
        .then((data) => {
          this.login();
          this.showLoading();
        })
        .catch((error) => {
          this.$toasted.global.display_error({
            message: error,
          });
          // console.log(this.credentials.apiErrors);
        });
      /* this.loading = true;
      window.axios
        .post('api/authenticate', this.postParams)
        .then(({ data }) => {
          this.loading = false;
          // success
          console.log('success');
        })
        .catch(error => {
          this.loading = false;
          this.hasErrors = true;

          const { status, data } = error.response;

          if (status === 401) {
            this.$toasted.error(data.message);
            return;
          }

          if (status === 422) {
            this.reportValidationErrors(data);
            return;
          }

          throw error;
        }); */
    },
  },
};
</script>

<style scoped lang="scss">
.login-box {
  width: 550px;
  margin: 4% auto;
}
@media (max-width: 767px) {
  .login-box {
    width: 100%;
    margin: 4% auto;
  }
}
.btn-login {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}
.login-border-light {
  border-color: #d7dce4;
}
</style>
