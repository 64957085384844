<template>
  <section class="about">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-8 mt-4">
          <div class="d-flex justify-content-between">
            <h2 class="font-weight-bold">ข้อมูลส่วนตัว</h2>
          </div>
        </div>
      </div>
      <form @submit.prevent="submit" v-promise-btn="{ action: 'submit' }">
        <div class="row mb-3">
          <div class="col-12 col-md-3 text-md-right">ประเภทผู้ใช้</div>
          <div class="col-12 col-md-8">
            {{ role }}
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-md-3 text-md-right">
            <label for="form_name" style="line-height: 47px">ชื่อ</label>
          </div>
          <div class="col-md-8">
            <input
              id="form_name"
              name="name"
              type="text"
              class="form-control"
              v-model="settings.name"
              :placeholder="ชื่อ"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 offset-md-3">
            <button class="btn btn-action" type="submit">
              {{ $t('common.save') }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import Form from '../modules/form/form';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  name: 'settings',
  data() {
    return {
      // projectEdit: null,
      settings: null,
    };
  },
  mounted() {
    this.settings = new Form({
      data: () => {
        return {
          name: this.user.name,
          config: {
            resetAfterSuccess: false,
          },
        };
      },
    });
  },
  computed: {
    ...mapState(['meta', 'user', 'provinces']),
    ...mapGetters(['role', 'isPeno']),
  },
  methods: {
    ...mapMutations(['setUser']),
    submit() {
      return this.settings
        .post('/api/settings')
        .then((response) => {
          this.setUser(response.user);
          this.$toasted.global.display_success({
            message: 'บันทึกการเปลี่ยนแปลงแล้ว',
          });
        })
        .catch((error) => {
          this.$toasted.global.display_error({
            message: error,
          });
        });
    },
  },
};
</script>

<style scoped></style>
