import Vue from 'vue';
import VueI18n from 'vue-i18n';
import locales from '../../locales';

Vue.use(VueI18n);
// console.log(locales);

const i18n = new VueI18n({
  locale: 'th',
  fallbackLocale: 'en',
  // messages: loadLocaleMessages()
  silentTranslationWarn: true,
  messages: locales,
});

// old for en.json and th.json only
/* function loadLocaleMessages () {
  const locales = require.context('../../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
} */

export default i18n;
