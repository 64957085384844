<template>
  <div
    class="
      text-center
      d-flex
      flex-row
      justify-content-center
      align-items-center
      h-75
    "
  >
    <h2>404 Not Found</h2>
  </div>
</template>

<script>
export default {
  name: 'not-found',
};
</script>

<style scoped></style>
