let components = [];
let target = { en: {}, th: {} };

components.push(require('./components/unit.js'));
components.push(require('./components/login.js'));
components.push(require('./components/validation.js'));
components.push(require('./components/common.js'));
components.push(require('./components/energy.js'));

components.forEach((component) => {
  const key = Object.keys(component.th)[0];
  target.en[key] = component.en[key];
  target.th[key] = component.th[key];
});

const translate = require('./components/translate.js');
Object.keys(translate.en).forEach((key) => {
  target.en[key] = translate.en[key];
});
Object.keys(translate.th).forEach((key) => {
  target.th[key] = translate.th[key];
});

export default target;
