<template>
  <section class="loading">
    <vue-element-loading
      :active="true"
      spinner="bar-fade-scale"
      :is-full-screen="true"
    />
  </section>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import { get } from 'lodash';

export default {
  name: 'GovIdCallback',
  data() {
    return {};
  },
  mounted() {
    this.authenticate();
  },
  computed: {
    ...mapGetters(['govIdCallbackUrl']),
  },
  methods: {
    ...mapMutations('auth', ['login']),
    ...mapMutations(['showLoading']),
    authenticate() {
      const params = this.$route.query || {};
      return window.axios
        .post('/api/govid/authenticate', {
          callback_url: this.govIdCallbackUrl,
          openid: params,
        })
        .then((response) => {
          this.login();
          this.showLoading();
        })
        .catch((error) => {
          const errorMessage = get(error, 'response.data.error', '');
          this.$toasted.global.display_error({
            message: errorMessage,
          });
          this.$router.push({ name: 'login' });
        });
    },
  },
};
</script>
