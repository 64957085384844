import Vue from 'vue';
import Router from 'vue-router';
import before from './middleware/before';
import after from './middleware/after';
import store from './store';
import routeImporter from './modules/importers/routeImporter';

Vue.use(Router);

const routes = routeImporter(require.context('./routes', false, /.*\.js$/));

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(before);
router.afterEach(after);

export default router;
