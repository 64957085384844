const Dashboard = () => import('../views/layouts/AdminDashboard.vue');
const NationalPlanSettings = () =>
  import('../views/admin/NationalPlanSettings.vue');
const ManageUsers = () =>
  import('../views/admin/ManageUsers.vue');
const ApiSetting = () =>
  import('../views/admin/ApiSetting.vue');
const PenoImport = () => import('../views/admin/PenoImport.vue');

export default [
  {
    path: '/admin',
    component: Dashboard,
    children: [
      {
        path: 'national-plan-settings',
        name: 'admin.national_plan_settings',
        component: NationalPlanSettings,
      },
      {
        path: 'peno/import',
        name: 'admin.peno.import',
        component: PenoImport,
      },
      {
        path: 'manage-users',
        name: 'admin.manage_users',
        component: ManageUsers,
      },
      {
        path: 'toggle-api',
        name: 'admin.toggle_api',
        component: ApiSetting,
      },
    ],
  },
];
