module.exports = {
  en: {
    energy: {
      ee: 'เพิ่มประสิทธิภาพการอนุรักษ์พลังงาน (EE)',
      re: 'พลังงานทดแทน (RE)',
    },
  },
  th: {
    energy: {
      ee: 'เพิ่มประสิทธิภาพการอนุรักษ์พลังงาน (EE)',
      re: 'พลังงานทดแทน (RE)',
      save: 'บันทึก',
    },
  },
};
