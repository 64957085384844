module.exports = {
  en: {
    login: {
      welcome: 'Welcome',
      please_signin: 'Please Signin',
      do_energy_provincial_plan: '',
    },
  },
  th: {
    login: {
      welcome: 'ยินดีต้อนรับ',
      please_signin: 'กรุณาล็อกอินเข้าสู่ระบบ',
      do_energy_provincial_plan: 'จัดทำแผนปฏิบัติราชการด้านพลังงานของจังหวัด',
    },
  },
};
