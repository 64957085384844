<template>
  <div class="home">
    <!--<img alt="Vue logo" src="../assets/logo.png" />-->
    <!--<HelloWorld msg="Welcome to Your Vue.js App" />-->
    Home
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  name: 'home',
  // components: {
  //   HelloWorld
  // },
  computed: {
    ...mapGetters(['isPso', 'isPeno', 'isAdmin']),
  },
  created() {
    if (this.isPeno) {
      this.$router.push({ name: 'peno.overview.intro' });
    } else if (this.isPso) {
      this.$router.push({ name: 'pso.overview' });
    } else if (this.isAdmin) {
      this.$router.push({ name: 'admin.national_plan_settings' });
    }
  },
};
</script>
