import Vue from 'vue';
import Numeral from 'numeral';
import i18n from './i18n';
import moment from 'moment-timezone';
import 'moment/locale/th';

Vue.filter('money', function (value, decimal_point = true, unit = false) {
  if (Number.isInteger(value) && (value === 0 || value === '0')) {
    return '0';
  }
  if (!value) {
    return '';
  }
  let format = '0,0.00';
  if (!decimal_point) {
    format = '0,0';
  }
  const result = Numeral(value).format(format);
  return result + (typeof unit === 'string' ? unit : '');
});

Vue.filter('million_baht', function (value) {
  if (!value) {
    return '';
  }
  const million = Numeral(value).divide(1000000.0);
  let format = '0,0.00';
  if (million.value() >= 1) {
    format = '0,0';
  }
  const result = million.format(format);
  return result + ' ' + i18n.t('unit.million_baht');
});

Vue.filter('percentage', function (value, divisor = null) {
  if (value == 0) {
    return '0%';
  }
  if (!value) {
    return '';
  }
  if (divisor) {
    value = value / divisor;
  }
  return Numeral(value).format('0%');
});

Vue.filter('thai_date', function (value, time = false, format = 'D MMM') {
  if (!value) {
    return '';
  }
  const date = moment.utc(value);
  if (!date.isValid()) {
    return value;
  }
  return (
    date.local().format(format) +
    ' ' +
    date.local().add(543, 'years').format('YYYY') +
    (time ? ' ' + date.local().format('HH:mm') : '')
  );
});

Vue.filter('days_from_now', function (value, absolute = true) {
  if (absolute) {
    return Math.abs(Math.floor((Date.now() - Date.parse(value)) / 86400000));
  }
  return Math.floor((Date.parse(value) - Date.now()) / 86400000);
});

Vue.filter('thai_year', function (value) {
  if (!value) {
    return '';
  }
  return moment().set('year', value).add(543, 'years').format('YYYY');
});

Vue.filter('nl2br', function (text, reg = /\n|\r|\n\r/g) {
  if (text && text !== null) {
    let i,
      s = '',
      lines = text.split(reg),
      l = lines.length;

    for (i = 0; i < l; ++i) {
      s += lines[i];
      i !== l - 1 && (s += '<br/>');
    }

    return s;
  }
  return text;
});

Vue.filter('province_name_locale', function (province) {
  if (!province) {
    return '';
  }
  return province['name_' + i18n.locale];
});

Vue.filter('report_status', function (version) {
  switch (version) {
    case 1:
      return 'ร่าง';
    case 2:
      return 'ส่งแผนครั้งที่ 1';
    case 3:
      return 'ส่งแผนครั้งที่ 2';
    default:
      return 'ไม่ระบุ';
  }
});

Vue.filter('excerpt', function (value, length = 230) {
  if (!value) return '';
  value = value.toString();
  if (value.length > length) {
    return value.substring(0, length) + ' ...';
  } else {
    return value;
  }
});

Vue.filter('auto_number', function (value, unit = false, decimal = 2) {
  if (!value && value !== 0) {
    return '';
  }
  if (isNaN(value)) {
    return value;
  }
  let format =
    '0,0' + (parseInt(decimal) > 0 ? '.[' + '0'.repeat(decimal) : ']');
  if (!String(value).includes('.')) {
    format = '0,0';
  }
  let result = Numeral(value).format(format);
  if (Numeral(result).value() <= 0) {
    decimal = 4;
    format = '0,0' + (parseInt(decimal) > 0 ? '.[' + '0'.repeat(decimal) : ']');
    result = Numeral(value).format(format);
  }
  return result + (typeof unit === 'string' ? unit : '');
});

Vue.filter('number_abs', function (value) {
  return Math.abs(value);
});
