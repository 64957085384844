import Home from '../views/Home.vue';
import Login from '../views/Login.vue';
import GovIdCallback from '../views/GovIdCallback.vue';
import Settings from '../views/Settings.vue';
import NotFound from '../views/NotFound.vue';
import Unauthorized from '../views/Unauthorized.vue';

export default [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/settings',
    name: 'settings',
    component: Settings,
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      guest: true,
    },
  },
  {
    path: '/govid/callback',
    name: 'govid.callback',
    component: GovIdCallback,
    meta: {
      guest: true,
    },
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/map',
    name: 'map',
    component: () => import('../views/dev/Map.vue'),
    meta: {
      guest: true,
    },
  },
  {
    path: '/map2',
    name: 'map2',
    component: () => import('../views/dev/Map2.vue'),
    meta: {
      guest: true,
    },
  },
  {
    path: '/user-setup',
    name: 'user-setup',
    component: () => import('../views/UserSetup'),
    meta: {
      guest: true,
    },
  },
  {
    // global not found
    path: '/unauthorized',
    name: 'unauthorized',
    component: Unauthorized,
  },
  {
    // global not found
    path: '*',
    name: 'not_found',
    component: NotFound,
  },
];
