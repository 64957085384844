export default function (role) {
  let text = '';
  switch (role.toLowerCase()) {
    case 'superadmin':
      text = 'Super Admin';
      break;
    case 'admin':
      text = 'Admin';
      break;
    case 'pso':
      text = 'ผู้เชี่ยวชาญด้านข้อมูล + ผู้ดูแลระบบ';
      break;
    case 'peno':
      text = 'เจ้าหน้าที่พลังงานจังหวัด (สพจ.)';
      break;
    case 'cmu':
      text = 'CMU';
      break;
    default:
      text = role;
      break;
  }
  return text;
}
