module.exports = {
  en: {
    validation: {
      common: {
        email: {
          required: 'The email is required.',
          email: 'This is not a valid email address.',
        },
        password: {
          required: 'The password is required.',
          email: 'The password must be at least 8 characters long.',
        },
        password_confirmation: {
          required: 'The password confirmation is required.',
          sameAsPassword: 'The password confirmation does not match password.',
        },
      },
    },
  },
  th: {
    validation: {
      common: {
        email: {
          required: 'กรุณาใส่อีเมล',
          email: 'กรุณาใส่อีเมลที่ถูกต้อง',
        },
        password: {
          required: 'กรุณาใส่รหัสผ่าน',
          email: 'รหัสผ่านต้องยาวอย่างน้อย 8 ตัวอักษร',
        },
        password_confirmation: {
          required: 'กรุณายืนยันรหัสผ่าน',
          sameAsPassword: 'ยืนยันรหัสผ่านไม่ตรงกับรหัสผ่าน',
        },
      },
    },
  },
};
