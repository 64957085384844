import Vue from 'vue';
import * as VueGoogleMaps from 'vue2-google-maps';
import BootstrapVue from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import {i18n} from './modules/plugins';
import router from './router';
import store from './store';
import {isObject} from 'lodash';
import EvaIcons from 'vue-eva-icons';
import VModal from 'vue-js-modal';
import PrettyCheckbox from 'pretty-checkbox-vue';
import 'pretty-checkbox/src/pretty-checkbox.scss';
import VueElementLoading from './components/VueElementLoading';
import VuePromiseBtn from 'vue-promise-btn';
import 'vue-promise-btn/dist/vue-promise-btn.css';
import VueGoogleCharts from 'vue-google-charts';
import App from './App.vue';
import Affix from 'vue-affix';
import VueTrumbowyg from 'vue-trumbowyg';
import 'trumbowyg/dist/ui/trumbowyg.css';
import 'trumbowyg/dist/plugins/table/trumbowyg.table';
import 'trumbowyg/dist/plugins/fontsize/trumbowyg.fontsize';
import 'trumbowyg/dist/plugins/fontfamily/trumbowyg.fontfamily';
import 'trumbowyg/dist/plugins/upload/trumbowyg.upload';
import VueVisible from 'vue-visible';
import VTooltip from 'v-tooltip';
import VueClipboard from 'vue-clipboard2';
import Toasted from 'vue-toasted';
import VueAutonumeric from 'vue-autonumeric';
import VueScrollTo from 'vue-scrollto';
import './assets/trumbowyg/plugins/cleanpaste.js';
import './assets/trumbowyg/plugins/smaller.js';
import UserRoles from './filters/user_roles';

Vue.use(BootstrapVue);
Vue.use(EvaIcons);
Vue.use(VModal, {dynamic: true});
Vue.use(PrettyCheckbox);
Vue.use(VuePromiseBtn);
Vue.use(VueGoogleCharts);
Vue.use(Affix);
Vue.use(VueTrumbowyg);
Vue.use(VueVisible);
Vue.use(VTooltip);
Vue.use(VueClipboard);
Vue.use(Toasted);
Vue.use(VueScrollTo, {
  container: 'body',
  duration: 500,
  easing: 'ease',
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
});

Vue.component('VueElementLoading', VueElementLoading);
Vue.component('vue-autonumeric', VueAutonumeric);

Vue.config.productionTip = false;

// window.Vue = Vue;

Vue.toasted.register('copied', 'Copied', {
  position: 'bottom-right',
  type: 'info',
  duration: 1000,
});
Vue.toasted.register(
  'display_error',
  (payload) => {
    // if there is no message passed show default message
    if (!payload.message) {
      return 'เกิดข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ';
    }
    if (isObject(payload.message)) {
      const error = payload.message;
      if (
        error.response &&
        error.response.status &&
        error.response.status == 422
      ) {
        payload.message = 'ข้อมูลไม่ถูกต้อง';
      } else if (
        error.response &&
        error.response.status &&
        error.response.status == 500
      ) {
        payload.message =
          'มีข้อผิดพลาดเกิดขึ้นในระบบ กรุณาลองอีกครั้ง หรือติดต่อผู้ดูแลระบบ';
      } else if (
        error.response &&
        error.response.status &&
        error.response.status == 400 &&
        error.response.data &&
        error.response.data.message
      ) {
        payload.message = error.response.data.message;
      }
    }
    // if there is a message show it with the message
    return payload.message;
  },
  {
    position: 'bottom-right',
    type: 'error',
    duration: 5000,
  }
);
Vue.toasted.register(
  'display_success',
  (payload) => {
    if (!payload.message) {
      return 'สำเร็จ';
    }
    return payload.message;
  },
  {
    position: 'bottom-right',
    type: 'success',
    duration: 3000,
  }
);

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAXgiY0gZmWdb3VqOKuOCMpesmI1j4f53Q',
    libraries: 'places', // This is required if you use the Autocomplete plugin
  },
});

Vue.prototype.$fontfamilyList = [
  {name: 'TH Sarabun New', family: 'THSarabunNew, Helvetica, sans-serif'},
  {name: 'Arial', family: 'Arial, Helvetica, sans-serif'},
  {name: 'Arial Black', family: 'Arial Black, Gadget, sans-serif'},
  {name: 'Courier New', family: 'Courier New, Courier, monospace'},
  {
    name: 'Palatino',
    family: 'Palatino Linotype, Book Antiqua, Palatino, serif',
  },
  {name: 'Tahoma', family: 'Tahoma, Geneva, sans-serif'},
  {name: 'Times New Roman', family: 'Times New Roman, Times, serif'},
  {name: 'Trebuchet', family: 'Trebuchet MS, Helvetica, sans-serif'},
  {name: 'Verdana', family: 'Verdana, Geneva, sans-serif'},
];

Vue.filter('user_roles', UserRoles);

window.Vue = new Vue({
  router,
  i18n,
  store,
  ...App,
}).$mount('#app');
