<template>
  <b-nav-item-dropdown
    :text="'Lang: ' + currentLocaleDisplay"
    right
    class="locales pointer h-100 d-md-flex align-items-center"
  >
    <b-dropdown-item
      class="text-center"
      tabindex="-1"
      v-for="(locale, key) in locales"
      :key="key"
      @click="changeLocale(key)"
      >{{ locale.display }}</b-dropdown-item
    >
  </b-nav-item-dropdown>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'LocaleChanger',

  computed: {
    ...mapState(['locales']),
    ...mapGetters(['currentLocaleDisplay']),
  },

  methods: {
    changeLocale(lang) {
      this.$i18n.locale = lang;
    },
  },
};
</script>

<style lang="scss">
@import '../assets/scss/mixins/all';

.locales {
  font-size: 1.1rem;
  .dropdown-toggle:after {
    vertical-align: 0.05em;
    @include helper-color;
  }
  .dropdown-menu {
    min-width: 6rem;
  }
  .dropdown-toggle span {
    @include helper-color;
  }
}
@media (max-width: 767px) {
  .dropdown-menu {
    margin-bottom: 20px;
  }
}
</style>
