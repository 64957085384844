import store from '../../store';

const exceptions = ['not_found', 'unauthorized'];

export default (to, from, next) => {
  if (!store.state.isInitialised) {
    next();
  } else {
    let deniedRoles = [];
    let allowRoles = [];
    to.matched.forEach((route) => {
      if (route.meta.deniedRoles) {
        deniedRoles = Array.isArray(route.meta.deniedRoles)
          ? route.meta.deniedRoles
          : [route.meta.deniedRoles];
      }
      if (route.meta.allowRoles) {
        allowRoles = Array.isArray(route.meta.allowRoles)
          ? route.meta.allowRoles
          : [route.meta.allowRoles];
      }
    });
    if (deniedRoles && deniedRoles.length > 0) {
      deniedRoles.forEach((role) => {
        if (store.getters.isRole(role)) {
          next({ name: 'home' });
        }
      });
    }
    if (allowRoles && allowRoles.length > 0) {
      let allow = false;
      allowRoles.forEach((role) => {
        if (store.getters.isRole(role)) {
          allow = true;
        }
      });
      if (!allow) {
        next({ name: 'home' });
      }
    }
    next();
  }
};
