<template>
  <section>
    <div v-if="isImpersonating" class="impersonated-menu p-1 w-full" v-cloak>
      <span class="text-white text-small">คุณกำลังดูแผนของจังหวัด{{ userTitle }}</span>
      <button class="ml-4 btn btn-danger btn-sm text-small" @click="stopImpersonating">หยุดมองมุมมอง สพจ.</button>
    </div>
    <b-navbar
      toggleable="md"
      type="light"
      id="nav"
      class="pr-md-0 pl-1 px-sm-3"
    >
      <router-link to="/" class="navbar-brand mr-0 mr-sm-3">
        <img alt="Energy logo" src="../../assets/logo-energy.png" />
      </router-link>
      <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>

      <b-collapse is-nav id="nav_collapse" class="default-collapse">
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto d-flex align-items-center default-nav">
          <div v-if="isPeno">
            <router-link
              v-if="isPenoPlan"
              :to="{ name: 'peno.overview.intro' }"
              class="
                ml-3
                text-gray
                font-weight-normal
                text-normal
                pointer
                link-unstyle"
            >
              <eva-icon fill="#A8A0A0" name="layout"></eva-icon>
              หน้าหลัก
            </router-link>
            <router-link
              v-if="!isPenoSelectProvince"
              :to="{ name: 'peno.index' }"
              class="
                ml-3
                mr-3
                text-gray
                font-weight-normal
                text-normal
                pointer
                link-unstyle"
            >
              <eva-icon fill="#A8A0A0" name="compass"></eva-icon>
              เลือกจังหวัด
            </router-link>
          </div>
          <!-- <locale-changer></locale-changer> -->
          <li class="nav-item d-md-none mt-4 mb-4 w-100">
            <div class="d-flex justify-content-between">
              <div class="flex-grow-1 mr-3 helper-color">
                <div class="text-left">{{ user.name }}</div>
                <div class="text-left">{{ userTitle | user_roles }}</div>
              </div>
              <button
                type="button"
                @click="settings"
                class="mt-auto mb-auto btn btn-outline-secondary helper-color"
              >
                ตั้งค่า
              </button>
              <button
                type="button"
                @click="logout"
                class="
                  mt-auto
                  mb-auto
                  ml-3
                  btn btn-outline-secondary
                  helper-color
                "
              >
                ออกจากระบบ
              </button>
            </div>
          </li>
          <b-nav-item-dropdown
            right
            class="
              h-100
              border-left border-little-light
              pointer
              d-none d-md-inline
            "
            no-caret
          >
            <template slot="button-content">
              <div class="pl-2 d-md-flex flex-column user-nav helper-color">
                <div class="text-left h-50 text-short">{{ user.name }}</div>
                <div class="text-left">{{ userTitle | user_roles }}</div>
              </div>
            </template>
            <b-dropdown-item
              class="user-dropdown"
              tabindex="-1"
              @click="settings"
            >ตั้งค่า
            </b-dropdown-item
            >
            <b-dropdown-item class="user-dropdown" tabindex="-1" @click="logout"
            >ออกจากระบบ
            </b-dropdown-item
            >
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <transition mode="out-in" name="fade">
      <router-view />
    </transition>
  </section>
</template>

<script>
// import LocaleChanger from '@/components/LocaleChanger.vue';
import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';
import axios from 'axios';
import {head} from 'lodash';

export default {
  name: 'Default',
  // components: { LocaleChanger },
  mounted() {
    // console.log('Impersonated: ' + this.isImpersonating);
  },
  computed: {
    ...mapGetters('auth', ['isImpersonating', 'impersonatingFrom']),
    ...mapState(['user']),
    ...mapGetters(['userProvinceName', 'isPeno', 'role']),
    isPenoSelectProvince() {
      return this.$route.name == 'peno.index';
    },
    isPenoPlan() {
      return !(
        this.$route.name == 'peno.index' ||
        this.$route.name == 'peno.overview.intro' ||
        this.$route.name == 'peno.overview.province_energy'
      );
    },
    userTitle() {
      const title = this.userProvinceName || head(this.user.roles).name;
      if (title == 'PSO') {
        return 'Admin';
      }
      return title;
    },
  },
  methods: {
    ...mapActions('auth', ['setImpersonate', 'setImpersonatingFrom']),
    settings() {
      this.$router.push({name: 'settings'});
    },
    logout() {
      axios
        .post('/api/logout')
        .then(() => {
          this.$store.commit('setUser', null);
          this.$store.commit('auth/logout');
          this.$router.push({name: 'login'});
        })
        .catch((error) => {
        });
    },
    stopImpersonating() {
      axios
        .post('/api/stop-impersonate', {backPage: this.impersonatingFrom})
        .then((response) => {
          this.setImpersonate(false);
          this.setImpersonatingFrom(null);
          window.location.href = response.data.redirect;
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
};
</script>

<style lang="scss" scoped>
.default {
}

.user-nav {
  height: 2.625rem;
  width: 20vw;
}

.user-dropdown {
  width: calc(20vw + 1rem);
}

.text-short {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media (min-width: 768px) {
  .default-collapse {
    height: 3.625rem;
  }
  .default-nav {
    height: 100%;
  }
}
</style>
<style lang="scss">
.default-collapse {
  .dropdown-toggle:after {
    vertical-align: 0.05em;
  }
}

.impersonated-menu {
  button {
    float: right;
  }

  border-bottom: 1px solid #dddddd;
  background-color: #ea5835;
}
</style>
