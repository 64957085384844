import Vue from 'vue';
import axios from 'axios';
import { validationMixin } from 'vuelidate';
import { debounce, cloneDeep, get } from 'lodash';
import Errors from './error';
import score from './validationScore';

/* eslint-disable no-underscore-dangle */
export default Vue.extend({
  mixins: [validationMixin],
  data() {
    return {
      originalData: {},
      apiErrors: new Errors(),
      submitting: false,
      config: {
        resetAfterSuccess: true,
        // setComputedPayloadBackToForm: true,
        validationPrefix: 'common',
        transformToPayload: this.defaultTransformToPayload,
        transformToForm: this.defaultTransformToForm,
      },
    };
  },
  // resetAfterSuccess: true,
  // validationPrefix: 'common',
  validations: {},
  testProperty: true,
  created() {
    // console.log(this.$data);

    const data = {};

    Object.keys(this.$data).forEach((key) => {
      if (
        ['apiErrors', 'submitting', 'originalData', 'config'].indexOf(key) ===
        -1
      ) {
        data[key] = this[key];
      }
    });

    this.$data.originalData = data;

    const option = this.$options;
    // const optionArray = ['resetAfterSuccess', 'validationPrefix', 'transformToPayload'];
    const optionArray = Object.keys(this.$data.config);
    optionArray.forEach((op) => {
      if (Object.prototype.hasOwnProperty.call(option, op)) {
        this.config[op] = option[op];
      }
    });
  },
  computed: {
    /**
     * Disable submit button helper
     */
    disabled() {
      return this.submitting || this.$v.$error;
    },

    /**
     * Get payload for API request
     * @returns {Object}
     */
    payload() {
      const data = {};
      if (this.originalData) {
        Object.keys(this.originalData).forEach((key) => {
          data[key] = this[key];
        });
      }
      // if (this.text) {
      //   data['text'] = this.text;
      // }
      // transformer
      // const payload = this.config.transformToPayload(data);
      return this.config.transformToPayload(data);
    },

    /**
     * Get errors messages
     * @returns {Object}
     */
    errors() {
      const errors = {
        loGet(path) {
          return get(this, path);
        },
      };
      const prefix = this.config.validationPrefix;

      /*Object.keys(this.originalData).forEach(field => {
                                                                                                              this.recursivelyAddErrors(this.$v[field], errors, prefix, [], field);
                                                                                                            });*/

      Object.keys(this.originalData).forEach((field) => {
        if (this.$v[field] && this.$v[field].$error) {
          const rulesArray = Object.keys(this.$v[field]).filter(
            (key) => !key.startsWith('$') && this.$v[field][key] === false
          );
          if (rulesArray.length > 0) {
            if (
              window.Vue.$t(`validation.${prefix}.${field}.${rulesArray[0]}`)
            ) {
              // check locale exists
              if (rulesArray[0] === 'between') {
                Vue.set(
                  errors,
                  field,
                  window.Vue.$t(
                    `validation.${prefix}.${field}.${rulesArray[0]}`,
                    {
                      min: this.$v[field].$params.between.min,
                      max: this.$v[field].$params.between.max,
                    }
                  )
                );
              } else {
                Vue.set(
                  errors,
                  field,
                  window.Vue.$t(
                    `validation.${prefix}.${field}.${rulesArray[0]}`
                  )
                );
              }
            } else {
              Vue.set(
                errors,
                field,
                window.Vue.$t(`validation.${prefix}.${field}.fallback`)
              );
            }
          }
        } else if (this.apiErrors.has(field)) {
          Vue.set(errors, field, this.apiErrors.get(field));
        }
      });

      if (this.apiErrors.has('common')) {
        errors.common = this.apiErrors.get('common');
      }

      return errors;
    },
  },
  methods: {
    defaultTransformToPayload(payload) {
      return payload;
    },
    defaultTransformToForm(payload) {
      return payload;
    },
    setData(data, except, custom) {
      // if except is string, transform to array
      let exceptArray = [];
      if (except) {
        if (typeof except === 'string' || except instanceof String) {
          exceptArray.push(except);
        }
        if (Array.isArray(except)) {
          exceptArray = except;
        }
      }

      // loop each key and set data.
      Object.keys(this.originalData).forEach((field) => {
        // console.log(`check ${field}`);
        if (data[field]) {
          if (exceptArray.includes(field)) {
            return;
          }

          this[field] = cloneDeep(data[field]);
          // window.Vue.set(this, field, data[field]);
        }
      });

      // run custom function if provided.
      if (custom) {
        Object.keys(custom).forEach((key) => {
          const convertFn = custom[key];
          this[key] = convertFn(data[key]);
        });
      }
    },

    recursivelyAddErrors(
      validatorObj,
      errorsObj,
      prefix,
      precursorFieldNamesArr,
      field
    ) {
      let precursorFieldNames = precursorFieldNamesArr.join('.');
      if (precursorFieldNames.length > 0) {
        precursorFieldNames = `${precursorFieldNames}.`;
      }

      const fullFieldName = `${precursorFieldNames}${field}`;

      if (validatorObj && validatorObj.$error) {
        const rulesArray = Object.keys(validatorObj).filter(
          (key) => !key.startsWith('$') && validatorObj[key] === false
        );

        if (rulesArray.length > 0) {
          rulesArray.sort((a, b) => score[b] - score[a]);
          if (
            window.Vue.$t(
              `validation.${prefix}.${precursorFieldNames}${field}.${rulesArray[0]}`
            )
          ) {
            // check locale exists
            if (rulesArray[0] === 'between') {
              Vue.set(
                errorsObj,
                fullFieldName,
                window.Vue.$t(
                  `validation.${prefix}.${fullFieldName}.${rulesArray[0]}`,
                  {
                    min: this.$v[field].$params.between.min,
                    max: this.$v[field].$params.between.max,
                  }
                )
              );
            } else {
              Vue.set(
                errorsObj,
                fullFieldName,
                window.Vue.$t(
                  `validation.${prefix}.${fullFieldName}.${rulesArray[0]}`
                )
              );
            }
          } else {
            Vue.set(
              errorsObj,
              fullFieldName,
              window.Vue.$t(`validation.${prefix}.${fullFieldName}.fallback`)
            );
          }
        }
      } else if (this.apiErrors.has(fullFieldName)) {
        Vue.set(errorsObj, fullFieldName, this.apiErrors.get(fullFieldName));
      }

      if (validatorObj) {
        const nestedValidatorKeys = Object.keys(validatorObj).filter(
          (key) =>
            !key.startsWith('$') &&
            validatorObj[key] !== true &&
            validatorObj[key]
        );
        nestedValidatorKeys.forEach((key) => {
          const fieldNamesArr = precursorFieldNamesArr.slice();
          fieldNamesArr.push(field);
          this.recursivelyAddErrors(
            validatorObj[key],
            errorsObj,
            prefix,
            fieldNamesArr,
            key
          );
        });
      }
    },

    /* getError(path) {
                                                                          return get(this.apiErrors, path, '');
                                                                        }, */

    realtimeTouch(event) {
      const name = event.target.name;
      const validator = get(this.$v, name, null);
      if (validator !== null) {
        validator.$touch();
      }
    },

    touch: debounce(
      // touch 1s after type
      function touchInput(event, eventFallback) {
        if (!event.target) {
          event = eventFallback;
        }
        if (event.target) {
          this.realtimeTouch(event);
        }
      },
      1000 // wait 1 sec
    ),

    /**
     * Check form field has a rule error
     * @param field
     * @param key
     * @returns {boolean}
     */
    hasValidationError(field, key) {
      return key in this.$v[field] && !this.$v[field][key];
    },

    /**
     * Empty the form fields.
     */
    reset() {
      Object.keys(this.originalData).forEach((key) => {
        this[key] = '';
      });

      this.apiErrors.clear();
    },

    isDirty() {
      let dirty = false;
      Object.keys(this.originalData).forEach((key) => {
        if (this[key] !== this.originalData[key]) {
          dirty = true;
          return false;
        }
      });
      return dirty;
    },

    cleanDirty() {
      const newOriginalData = {};
      Object.keys(this.originalData).forEach((key) => {
        newOriginalData[key] = this[key];
      });
      this.originalData = newOriginalData;
    },

    /**
     * Reset the form fields to original values.
     */
    /* resetToOriginal() {
                                                                          Object.keys(this.originalData).forEach((key) => {
                                                                            this[key] = this.originalData[key];
                                                                          });

                                                                          this.apiErrors.clear();
                                                                        }, */

    /* rollback() {
                                                                          Object.keys(this.originalData).forEach((key) => {
                                                                            this[key] = cloneDeep(this.originalData[key]);
                                                                          });

                                                                          this.apiErrors.clear();
                                                                        }, */

    /**
     * Send a POST request to the given URL.
     * .
     * @param {string} url
     */
    post(url) {
      return this.submit('post', url);
    },

    /**
     * Send a PUT request to the given URL.
     * .
     * @param {string} url
     */
    put(url) {
      return this.submit('put', url);
    },

    /**
     * Send a PATCH request to the given URL.
     * .
     * @param {string} url
     */
    patch(url) {
      return this.submit('patch', url);
    },

    /**
     * Send a DELETE request to the given URL.
     * .
     * @param {string} url
     */
    delete(url) {
      return this.submit('delete', url);
    },

    /**
     * Submit the form.
     *
     * @param {string} requestType
     * @param {string} url
     */
    submit(requestType, url) {
      this.$v.$touch();
      if (!this.$v.$error) {
        this.apiErrors.clear();
        this.submitting = true;
        return new Promise((resolve, reject) => {
          axios[requestType](url, this.payload)
            .then((response) => {
              this.onSuccess(response.data);
              this.submitting = false;
              this.cleanDirty();
              resolve(response.data);
            })
            .catch((error) => {
              this.onFail(error);
              this.submitting = false;
              reject(error);
            });
        });
      }
      // frontend validation error, do not submit
      return Promise.reject('กรุณากรอกข้อมูลให้ถูกต้อง');
    },

    /**
     * Handle a successful form submission.
     *
     * @param {object} data
     */
    onSuccess(/* data */) {
      // alert(data.message); // temporary
      this.$v.$reset();
      if (this.config.resetAfterSuccess) {
        this.reset();
      }
      this.apiErrors.clear();
    },

    /**
     * Handle a failed form submission.
     *
     * @param {object} errors
     */
    onFail(error) {
      if (error.response) {
        const status = error.response.status;
        if (status === 422) {
          let errors = error.response.data.errors;
          if (this.config.transformToForm) {
            errors = this.config.transformToForm(errors);
          }
          this.apiErrors.record(errors);
        } else if (status === 412) {
          this.apiErrors.set('common', error.response.data.message);
        } else if (status === 401) {
          this.apiErrors.set('common', window.Vue.$t('error_messages.c401'));
        } else if (status === 429) {
          // console.log('429');
          this.apiErrors.set('common', window.Vue.$t('error_messages.c429'));
        } else if (status === 500) {
          // console.log('500');
          this.apiErrors.set('common', window.Vue.$t('error_messages.c500'));
        } else {
          this.apiErrors.set('common', window.Vue.$t('error_messages.common'));
        }
      } else {
        // problem with connection
        this.apiErrors.set(
          'common',
          window.Vue.$t('error_messages.no_connection')
        );
      }
    },

    /**
     * Remove an api error
     *
     * @param event
     */
    clearApiError(event) {
      this.apiErrors.clear(event.target.name);
    },
  },
});
