<template>
  <div id="app" class="">
    <progress-bar ref="progressBar" />
    <transition name="fade">
      <component :is="currentLayout" class="animated" style="height: 100%" />
    </transition>
  </div>
</template>

<script>
import ProgressBar from '@/components/ProgressBar.vue';
import { mapState, mapActions, mapGetters } from 'vuex';
import axios from 'axios';
import Auth from './views/layouts/Auth.vue';
import Default from './views/layouts/Default.vue';
import Loading from './views/layouts/Loading.vue';
// import Overlay from '../../components/enso/bulma/Overlay.vue';

export default {
  name: 'App',
  // components: { Overlay },
  components: {
    ProgressBar,
    Auth,
    Default,
    Loading,
  },
  data() {
    return {
      loading: true,
    };
  },

  computed: {
    ...mapGetters(['currentLayout']),
  },

  created() {},

  methods: {},
};
</script>

<style lang="scss">
@import 'assets/scss/app.scss';

body {
  background-color: #fbfbfd;
}
#app {
  font-family: 'THSarabunNew', 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#nav {
  background-color: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.08);
  /*z-index: 3;*/
  /*padding: 30px;*/
  /*a {*/
  /*font-weight: bold;*/
  /*color: #2c3e50;*/
  /*&.router-link-exact-active {*/
  /*color: #42b983;*/
  /*}*/
  /*}*/
  padding: 0 1rem;
  .navbar-brand img {
    height: 3rem;
  }
}
.trumbowyg-editor table {
  width: 100%;

  td {
    padding: 8px;
  }
}

.trumbowyg-dropdown-table {
  table {
    margin: 10px;
    display: inline-block;
  }

  table td {
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 1px;
    padding: 0;
    background-color: #fff;
    box-shadow: 0 0 0 1px #cecece inset;

    &.active {
      background-color: #00b393;
      box-shadow: none;
      cursor: pointer;
    }
  }
}
</style>
