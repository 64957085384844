<template>
  <section class="bg-login h-auto min-vh-100">
    <vue-element-loading
      :active="!ready"
      spinner="ring"
      :is-full-screen="true"
    />
    <b-navbar
      toggleable="md"
      type="light"
      id="nav"
      class="pr-md-0 pl-1 px-sm-3"
    >
      <router-link to="/" class="navbar-brand mr-0 mr-sm-3"
        ><img alt="Energy logo" src="../../assets/logo-energy.png"
      /></router-link>
      <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>
      <b-collapse is-nav id="nav_collapse">
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto h-100">
          <!-- <locale-changer></locale-changer> -->
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <div class="hero-body">
      <div class="container p-4">
        <router-view />
      </div>
    </div>
  </section>
</template>

<script>
import LocaleChanger from '@/components/LocaleChanger.vue';
import { mapState, mapGetters, mapMutations } from 'vuex';
import axios from 'axios';

export default {
  name: 'Auth',

  components: {},

  data: () => ({
    ready: false,
  }),

  computed: {},

  created() {
    this.init();
  },

  methods: {
    ...mapMutations(['setMeta', 'setCsrfToken']),
    init() {
      axios
        .get('/api/meta')
        .then(({ data }) => {
          this.setMeta(data.meta);
          this.setCsrfToken(data.meta.csrfToken);
          this.ready = true;
        })
        .catch((error) => {});
    },
  },
};
</script>

<style lang="scss">
.bg-login {
  background: #888 url('~@/assets/bg-login.jpg') no-repeat center;
  background-size: cover;
}
</style>
