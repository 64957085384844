import Vue from 'vue';
import Vuex from 'vuex';
import router from './router';
import storeImporter from './modules/importers/storeImporter';
import {i18n} from './modules/plugins';
import {values, head, maxBy, keyBy, find, isEmpty} from 'lodash';

Vue.use(Vuex);

const modules = storeImporter(require.context('./store', false, /.*\.js$/));

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules,
  state: {
    locales: {
      th: {display: 'ไทย'},
      en: {display: 'Eng'},
    },
    displayLoading: true,
    isInitialised: false,
    user: null,
    siteSetting: null,
    intendedRoute: null,
    nationalPlans: [],
    currentNationalPlanId: null,
    latestNationalPlanId: null,
    meta: {},
    requests: [],
    provinces: [],
    budgetSources: [
      {
        id: 1,
        title: 'งบแผ่นดินกระทรวงพลังงาน',
      },
      {
        id: 2,
        title: 'งบกองทุนเพื่อส่งเสริมการอนุรักษ์พลังงาน',
      },
      {
        id: 3,
        title: 'งบพัฒนาจังหวัด',
      },
      {
        id: 4,
        title: 'งบพัฒนากลุ่มจังหวัด',
      },
      {
        id: 5,
        title: 'งบกองทุนพัฒนารอบโรงไฟฟ้า',
      },
      {
        id: 6,
        title: 'งบอื่น',
      },
    ],
  },
  getters: {
    currentLocaleDisplay: (state) => state.locales[i18n.locale].display,
    requests: (state) => state.requests.length,
    requestIndex:
      (state) =>
        ({url, method}) =>
          state.requests.findIndex(
            (request) => method === request.method && url === request.url
          ),
    currentLayout: (state, commit, rootState) => {
      if (!rootState.auth.isAuth) {
        return 'auth';
      }
      return state.displayLoading ? 'loading' : 'default';
    },
    role: (state) => {
      let role = state.user.roles[0].name;
      if (role == 'PSO') {
        return 'Admin';
      }
      return role;
    },
    isPso: (state) => {
      return state.user && (head(state.user.roles) || []).name == 'PSO';
    },
    isPeno: (state) => {
      return state.user && (head(state.user.roles) || []).name == 'PEnO';
    },
    isAdmin: (state) => {
      return (
        state.user &&
        ((head(state.user.roles) || []).name == 'superadmin' ||
          (head(state.user.roles) || []).name == 'admin')
      );
    },
    isRole: (state) => (role) => {
      return (
        state.user && (head(state.user.roles) || []).name.toLowerCase() == role
      );
    },
    govIdCallbackUrl: (state) => {
      const callback = router.resolve({
        name: 'govid.callback',
      });
      return window.location.origin + callback.href;
    },
    getProvinceByISO: (state) => (iso3166_2) => {
      return find(state.provinces, {iso3166_2: iso3166_2});
    },
    getSiteSetting: (state) => {
      return state.siteSetting;
    },
    userProvinceName: (state) => {
      if (state.user) {
        const user = state.user;
        if (user.province) {
          return user.province['name_' + i18n.locale];
        }
      }
      return null;
    },
    reportTitle: (state) => (nationalPlan, province) => {
      const startYear = nationalPlan.start_budget_year;
      const endYear = nationalPlan.end_budget_year;
      const provinceName = province['name_' + i18n.locale];

      return (
        'แผนปฏิบัติราชการด้านพลังงานของจังหวัด' +
        provinceName +
        ' พ.ศ. ' +
        `${startYear} - ${endYear}` +
        (!isEmpty(nationalPlan.suffix_title)
          ? ` ${nationalPlan.suffix_title}`
          : '')
      );
    },
    nationalPlanList: (state) => {
      return values(state.nationalPlans);
    },
    nationalPlanByYear: (state) => (year) => {
      return find(values(state.nationalPlans), {year: parseInt(year)});
    },
    currentNationalPlan: (state) => {
      return state.nationalPlans[state.currentNationalPlanId];
    },
    latestNationalPlan: (state) => {
      return state.nationalPlans[state.latestNationalPlanId];
    },
    latestNationalPlanYearRange: (state, getters) => {
      return `${getters.latestNationalPlan.start_budget_year} - ${getters.latestNationalPlan.end_budget_year}`;
    },
    canEditCurrentNationalPlan: (state) => {
      return state.currentNationalPlanId === state.latestNationalPlanId;
    },
  },
  mutations: {
    showLoading: (state) => (state.displayLoading = true),
    hideLoading: (state) => (state.displayLoading = false),
    addRequest: (state, {method, url}) =>
      state.requests.push({method, url}),
    removeRequest: (state, index) => state.requests.splice(index, 1),
    setUser: (state, user) => (state.user = user),
    setMeta: (state, meta) => (state.meta = meta),
    setSiteSetting: (state, siteSetting) => (state.siteSetting = siteSetting),
    setProvinces: (state, provinces) => (state.provinces = provinces),
    setProvincesWithStatus: (state, provinces) => {
      const update = keyBy(provinces, 'id');
      state.provinces.forEach((province, index) => {
        province.progress = update[province.id]['progress'];
        province.status = update[province.id]['status'];
        Vue.set(state.provinces, index, province);
      });
    },
    setProvincesWithPreviousPlanEvalutaionScore: (state, provinces) => {
      const update = keyBy(provinces, 'id');
      state.provinces.forEach((province, index) => {
        province.previous_evaluation_score =
          update[province.id]['total_evaluation_score'];
        Vue.set(state.provinces, index, province);
      });
    },
    initialise: (state, value) => (state.isInitialised = value),
    setCsrfToken: (state, token) => {
      state.meta.csrfToken = token;
      window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token;
      window.Laravel = {
        csrfToken: token,
      };
    },
    setIntendedRoute: (state, route) => {
      state.intendedRoute = route;
    },
    setNationalPlans(state, nationalPlans) {
      state.nationalPlans = keyBy(nationalPlans, 'id');
      const latest = maxBy(nationalPlans, 'year');
      state.currentNationalPlanId = latest.id;
      state.latestNationalPlanId = latest.id;
    },
    setCurrentNationalPlan(state, nationalPlan) {
      state.currentNationalPlanId = nationalPlan.id;
    },
    setLatestNationalPlan(state, nationalPlan) {
      state.latestNationalPlanId = nationalPlan.id;
    },
    setNationalPlan(state, nationalPlan) {
      state.nationalPlans[nationalPlan.id] = nationalPlan;
    },
  },
  actions: {
    initialise({state, commit, dispatch, getters}) {
      commit('initialise', false);

      window.axios
        .get('/api/users/me')
        .then(({data}) => {
          commit('setUser', data.user);
          commit('setMeta', data.meta);
          commit('setSiteSetting', data.siteSetting);
          commit('setProvinces', data.meta.provinces);
          commit('setNationalPlans', data.meta.nationalPlans);
          commit('setCsrfToken', data.meta.csrfToken);
          commit('initialise', true);
        })
        .catch((error) => {
          commit('initialise', true);
        });
    },
    fetchProvincesStatus(
      {state, commit, rootState, rootGetters},
      nationalPlan
    ) {
      return window.axios
        .get('/api/national-plans/' + nationalPlan.id + '/province/status')
        .then(({data}) => {
          commit('setProvincesWithStatus', data);
          return data;
        });
    },
    fetchPreviousPlanProvincesEvaluationScore(
      {state, commit, rootState, rootGetters},
      nationalPlan
    ) {
      return window.axios
        .get(
          '/api/national-plans/' +
          nationalPlan.id +
          '/previous/province/evaluation_score'
        )
        .then(({data}) => {
          commit('setProvincesWithPreviousPlanEvalutaionScore', data);
          return data;
        });
    },
  },
});
