import axios from 'axios';
import router from '../../router';
import store from '../../store';
import { addRequest, removeRequest } from './requestManager';

window.axios = axios;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

axios.interceptors.request.use((config) => {
  addRequest(config);
  return config;
});

axios.interceptors.response.use(
  (response) => {
    removeRequest(response.config);
    return response;
  },
  (error) => {
    removeRequest(error.config);
    if (error.response) {
      const { status } = error.response;
      if ([401].includes(status)) {
        store.commit('setIntendedRoute', router.currentRoute);
        store.commit('auth/logout');
        router.push({ name: 'login' });
      } else if ([419].includes(status)) {
        window.location.reload();
      }
    }

    // if (status === 404) {
    //     this.$router.push({ name: 'notFound' });
    //     return;
    // }

    // if (status === 503) {
    //     window.location.reload();
    // }

    return Promise.reject(error);
  }
);
