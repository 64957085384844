import Vue from 'vue';
import axios from 'axios';
import {assign, map, keyBy, transform, sortBy} from 'lodash';

export const state = {
  currentReport: null,
  report: {},
  provinceSummaries: {},
  intro: {},
  previousYearProjects: null,
  budgetSources: [
    {
      id: 1,
      title: 'งบแผ่นดินกระทรวงพลังงาน',
    },
    {
      id: 2,
      title: 'งบกองทุนเพื่อส่งเสริมการอนุรักษ์พลังงาน',
    },
    {
      id: 3,
      title: 'งบพัฒนาจังหวัด',
    },
    {
      id: 4,
      title: 'งบพัฒนากลุ่มจังหวัด',
    },
    {
      id: 5,
      title: 'งบกองทุนพัฒนารอบโรงไฟฟ้า',
    },
    {
      id: 6,
      title: 'งบอื่น',
    },
  ],
  targetEconomies: [
    {
      id: 1,
      title: 'สาขาเกษตรกรรม การป่าไม้ และการประมง',
    },
    {
      id: 2,
      title: 'สาขาการทำเหมืองแร่และเหมืองหิน',
    },
    {
      id: 3,
      title: 'สาขาการผลิต',
    },
    {
      id: 4,
      title: 'สาขาไฟฟ้า ก๊าซฯ',
    },
    {
      id: 5,
      title: 'สาขาการประปาและการจัดการของเสีย',
    },
    {
      id: 6,
      title: 'สาขาการก่อสร้าง',
    },
    {
      id: 7,
      title: 'สาขาการขายส่ง การขายปลีกฯ',
    },
    {
      id: 8,
      title: 'สาขาการขนส่งและสถานที่เก็บสินค้า',
    },
    {
      id: 9,
      title: 'สาขาที่พักแรมและบริการด้านอาหาร',
    },
    {
      id: 10,
      title: 'สาขาข้อมูลข่าวสารและการสื่อสาร',
    },
    {
      id: 11,
      title: 'สาขากิจกรรมทางการเงินและการประกันภัย',
    },
    {
      id: 12,
      title: 'สาขากิจกรรมเกี่ยวกับอสังหาริมทรัพย์',
    },
    {
      id: 13,
      title: 'สาขากิจกรรมวิชาชีพฯ',
    },
    {
      id: 14,
      title: 'สาขากิจกรรมการบริหารและบริการสนับสนุนอื่น ๆ',
    },
    {
      id: 15,
      title: 'สาขาบริหารราชการฯ',
    },
    {
      id: 16,
      title: 'สาขาการศึกษา',
    },
    {
      id: 17,
      title: 'สาขากิจกรรมด้านสุขภาพฯ',
    },
    {
      id: 18,
      title: 'สาขาศิลปะ ความบันเทิงและนันทนาการ',
    },
    {
      id: 19,
      title: 'สาขากิจกรรมการบริการด้านอื่น ๆ',
    },
  ],
  allowEdit: true,
};

export const getters = {
  energySituations(state) {
    return state.currentReport ? state.currentReport.energy_situations : null;
  },
  currentProvince(state, getters, rootState) {
    return rootState.user ? rootState.user.province : null;
  },
  currentTargets(state, getters, rootState, rootGetters) {
    const comparator = transform(
      rootGetters.latestNationalPlan.national_strategies,
      (result, ns) => {
        result[ns.id] = ns.order;
      },
      {}
    );
    return (
      sortBy(state.currentReport.targets, (target) => {
        return comparator[target.national_strategy_id];
      }) || []
    );
  },
  getTargetEconomyOfProject(state) {
    return (project) => {
      return state.targetEconomies.find(
        (economy) => economy.id === project.target_economy
      );
    };
  },
  allowEdit(state) {
    return state.allowEdit;
  },
};

export const mutations = {
  setCurrentReport(state, report) {
    state.currentReport = report;
  },
  setIntro(state, intro) {
    state.intro = intro;
  },
  setIntroProjects(state, projectsData) {
    state.intro.projects_data[projectsData.year] = projectsData.projects_data;
  },
  setPreviousYearProjects(state, projectsData) {
    state.previousYearProjects = projectsData.projects;
  },
  updatePreviousYearProject(state, updatedProject) {
    const findProject = state.previousYearProjects.find(
      (item) => item.id === updatedProject.id
    );
    const index = state.previousYearProjects.indexOf(findProject);

    if (index === -1) {
      state.previousYearProjects.push(updatedProject);
    } else {
      state.previousYearProjects.splice(index, 1, updatedProject);
    }
  },
  setProvinceSummary(state, {summary, province}) {
    state.provinceSummaries[province.id] = summary;
  },
  setExecutiveSummary(state, executiveSummary) {
    Vue.set(state.currentReport, 'executive_summary', executiveSummary);
  },
  setAreaPlanStrategies(state, areaPlanStrategies) {
    Vue.set(state.currentReport, 'area_plan_strategies', areaPlanStrategies);
  },
  setRegionEnergyPlanInProvince(state, regionEnergyPlanInProvince) {
    Vue.set(
      state.currentReport,
      'region_energy_plan_in_province',
      regionEnergyPlanInProvince
    );
  },
  setProvinceGroupDevelopmentStrategyp(
    state,
    provinceGroupDevelopmentStrategy
  ) {
    Vue.set(
      state.currentReport,
      'province_group_development_strategy',
      provinceGroupDevelopmentStrategy
    );
  },
  updateEnergySituation(state, {type, updatedSituation}) {
    const oldSituation = state.currentReport.energy_situations.find(
      (situation) => {
        return situation.type == type;
      }
    );
    if (oldSituation) {
      const index = state.currentReport.energy_situations.indexOf(oldSituation);
      state.currentReport.energy_situations.splice(index, 1, updatedSituation);
    } else {
      state.currentReport.energy_situations.push(updatedSituation);
    }
  },
  updateScenario(state, updatedScenario) {
    state.currentReport.scenario = updatedScenario;
  },
  updateTarget(state, updatedTarget) {
    const oldTarget = state.currentReport.targets.find((t) => {
      return t.id == updatedTarget.id;
    });
    if (oldTarget) {
      const index = state.currentReport.targets.indexOf(oldTarget);
      state.currentReport.targets.splice(index, 1, updatedTarget);
    } else {
      state.currentReport.targets.push(updatedTarget);
    }
  },
  deleteTarget(state, deletedTarget) {
    if (deletedTarget.id) {
      // console.log(deletedTarget.id);
      const target = state.currentReport.targets.find((t) => {
        return t.id == deletedTarget.id;
      });
      if (target) {
        const targetIndex = state.currentReport.targets.indexOf(target);
        state.currentReport.targets.splice(targetIndex, 1);
      }
    }
  },
  updateProvinceStrategyOverview(
    state,
    {provinceStrategyOverview, provinceGroupStrategyOverview}
  ) {
    state.currentReport.province_strategy_overview = provinceStrategyOverview;
    state.currentReport.province_group_strategy_overview =
      provinceGroupStrategyOverview;
  },
  updateSwot(state, updatedSwot) {
    const oldTarget = state.currentReport.targets.find((t) => {
      return t.id == updatedSwot.target_id;
    });
    const oldTargetIndex = state.currentReport.targets.indexOf(oldTarget);
    const oldSwot = state.currentReport.targets[oldTargetIndex].swots.find(
      (s) => {
        return s.id == updatedSwot.id;
      }
    );

    if (oldSwot) {
      const oldSwotIndex =
        state.currentReport.targets[oldTargetIndex].swots.indexOf(oldSwot);
      state.currentReport.targets[oldTargetIndex].swots.splice(
        oldSwotIndex,
        1,
        updatedSwot
      );
    } else {
      state.currentReport.targets[oldTargetIndex].swots.push(updatedSwot);
    }
  },
  updateSwots(state, {updatedSwots, targetId}) {
    const target = state.currentReport.targets.find((t) => {
      return t.id == targetId;
    });
    const targetIndex = state.currentReport.targets.indexOf(target);
    state.currentReport.targets[targetIndex].swots = updatedSwots;
  },
  updateAppendixes(state, updatedAppendixes) {
    state.currentReport.appendixes = updatedAppendixes;
  },
  updateTargetStrategy(state, updatedTargetStrategy) {
    const oldTarget = state.currentReport.targets.find((t) => {
      return t.id == updatedTargetStrategy.target_id;
    });
    if (oldTarget) {
      const targetIndex = state.currentReport.targets.indexOf(oldTarget);
      const oldTargetStrategy = oldTarget.target_strategies
        ? oldTarget.target_strategies.find((ts) => {
          return ts.id == updatedTargetStrategy.id;
        })
        : null;
      if (oldTargetStrategy) {
        const index =
          state.currentReport.targets[targetIndex].target_strategies.indexOf(
            oldTargetStrategy
          );
        state.currentReport.targets[targetIndex].target_strategies.splice(
          index,
          1,
          updatedTargetStrategy
        );
      } else {
        state.currentReport.targets[targetIndex].target_strategies.push(
          updatedTargetStrategy
        );
      }
    }
  },
  deleteTargetStrategy(state, targetStrategy) {
    if (targetStrategy.id && targetStrategy.target_id) {
      const target = state.currentReport.targets.find((t) => {
        return t.id == targetStrategy.target_id;
      });
      if (target) {
        const targetIndex = state.currentReport.targets.indexOf(target);
        state.currentReport.targets[targetIndex].target_strategies =
          state.currentReport.targets[targetIndex].target_strategies.filter(
            (ts) => ts.id != targetStrategy.id
          );
      }
    }
  },
  setAllowEdit(state, allow) {
    state.allowEdit = allow;
  },
};

export const actions = {
  fetchReport(
    {state, commit, rootState, rootGetters},
    province,
    year = null
  ) {
    return axios.get('/api/current_report').then(({data}) => {
      commit('setCurrentReport', data);
      return data;
    });
  },
  fetchProvinceSummary(
    {state, commit, rootState, rootGetters},
    {nationalPlan, province}
  ) {
    return axios
      .get(
        '/api/national-plans/' +
        nationalPlan.id +
        '/province/' +
        province.id +
        '/summary'
      )
      .then(({data}) => {
        commit('setProvinceSummary', {
          summary: data,
          province: province,
        });
        return data;
      });
  },
  fetchIntro({state, commit, rootState, rootGetters}, {province}) {
    return axios
      .get('/api/provinces/' + province.id + '/intro')
      .then(({data}) => {
        commit('setIntro', data);
        return data;
      });
  },
  fetchIntroProjects(
    {state, commit, rootState, rootGetters},
    {province, year}
  ) {
    return axios
      .get('/api/provinces/' + province.id + '/intro/projects/' + year)
      .then(({data}) => {
        commit('setIntroProjects', data);
        return data;
      });
  },
  fetchPreviousYearProjects(
    {state, commit, rootState, rootGetters},
    {province}
  ) {
    return axios
      .get('/api/provinces/' + province.id + '/previous-year-projects')
      .then(({data}) => {
        commit('setPreviousYearProjects', data);
        return data;
      });
  },
  fetchChartImageUrl({state, commit, rootState, rootGetters}, url) {
    return axios.get(url).then(({data}) => {
      return data;
    });
  },
  fetchMondasChartImageUrl({state, commit, rootState, rootGetters}, url) {
    return axios
      .get('/chart/mondas_image', {
        params: {
          width: 1500,
          height: 1500,
          url: url,
        },
      })
      .then(({data}) => {
        return data;
      });
  },
};
