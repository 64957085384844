import axios from 'axios';
import { get, isEmpty, head, range, forIn, find, map, uniq } from 'lodash';
import Vue from 'vue';
import Decimal from 'decimal.js-light';

const THAILAND_KEY = '_THAILAND_';
const THAI_ENERGY_DATA_ID = 1;
const MONDAS_ID = 2;

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
  fetchProvinceGeneral(
    { state, commit, rootState, rootGetters },
    { year, province }
  ) {
    console.log(1, this.state.siteSetting)

    const provinceName = province.name_en;
    const provinceCode = province.iso3166_2.substr(3);

    if (this.state.siteSetting.value === THAI_ENERGY_DATA_ID) {
      return axios
        .get('https://data.energy.go.th/api/ipepp/output/province-general', {
          params: {
            year: year,
            province_code: provinceCode,
          },
        })
        .then(({data}) => {
          const result = {
            area: null,
            population: null,
            gpp: null,
            household: null,
            householdNoEl: null,
          };
          if (!isEmpty(data.result)) {
            const RDS_AreaAgr = get(data.result, 'RDS_AreaAgr.value', null);
            const RDS_AreaForest = get(data.result, 'RDS_AreaForest.value', null);
            const RDS_AreaNonAgr = get(data.result, 'RDS_AreaNonAgr.value', null);
            const totalAreaInRai = new Decimal(
              RDS_AreaAgr + RDS_AreaForest + RDS_AreaNonAgr
            );
            result.area = totalAreaInRai.dividedBy(625).toFixed(2);
            result.population = get(data.result, 'RDS_PopNo.value', null);
            result.gpp = get(data.result, 'RDS_GPP.value', null);
            result.household = get(data.result, 'RDS_HHNo.value', null);
            result.householdNoEl = get(data.result, 'RDS_HHNoWoEl.value', null);
            result.finalEnergyCons = get(
              data.result,
              'RDS_EnergyCons.value',
              null
            );
            let sources = uniq(map(data.result, 'source'));
            result.sources = sources;
          }
          return result;
        });
    } else {
      return axios
        .get('https://pemt-api.mondas.io/system/query', {
          params: {
            api_key: 'ExgWFH9XHHyXIHyKEHIH',
            format: 'hash',
            group: 'key',
            year: '2560', //year,
            key: 'RDS_AreaAgr,RDS_AreaForest,RDS_AreaNonAgr,RDS_PopNo,RDS_HHNo,RDS_HHNoWoEl,RDS_GPP,RDS_EnergyCons',
            province: provinceName, //province.name_en,
          },
        })
        .then(({data}) => {
          const result = {
            area: null,
            population: null,
            gpp: null,
            household: null,
            householdNoEl: null,
          };
          if (!isEmpty(data.result)) {
            const RDS_AreaAgr = get(data.result, 'RDS_AreaAgr.value', null);
            const RDS_AreaForest = get(data.result, 'RDS_AreaForest.value', null);
            const RDS_AreaNonAgr = get(data.result, 'RDS_AreaNonAgr.value', null);
            const totalAreaInRai = new Decimal(
              RDS_AreaAgr + RDS_AreaForest + RDS_AreaNonAgr
            );
            result.area = totalAreaInRai.dividedBy(625).toFixed(2);
            result.population = get(data.result, 'RDS_PopNo.value', null);
            result.gpp = get(data.result, 'RDS_GPP.value', null);
            result.household = get(data.result, 'RDS_HHNo.value', null);
            result.householdNoEl = get(data.result, 'RDS_HHNoWoEl.value', null);
            result.finalEnergyCons = get(
              data.result,
              'RDS_EnergyCons.value',
              null
            );
            let sources = uniq(map(data.result, 'source'));
            result.sources = sources;
          }
          return result;
        });
    }
  },
  fetchPreEnergyConsume(
    { state, commit, rootState, rootGetters },
    { province, key }
  ) {
    console.log(2, this.state.siteSetting)

    const provinceName = province.name_en;
    const provinceCode = province.iso3166_2.substr(3);

    if (this.state.siteSetting.value === THAI_ENERGY_DATA_ID) {
      return axios
        .get('https://data.energy.go.th/api/ipepp/output/pre-energy-consumption', {
          params: {
            key: key,
            province_code: provinceCode,
          },
        })
        .then(({data}) => {
          if (!isEmpty(data.result)) {
            return data.result;
          }
          return null;
        });
    } else {
      return axios
        .get('https://pemt-api.mondas.io/system/query', {
          params: {
            api_key: 'ExgWFH9XHHyXIHyKEHIH',
            energyunit: 'ktoe',
            key: key,
            province: provinceName, //province.name_en,
          },
        })
        .then(({data}) => {
          if (!isEmpty(data.result)) {
            return data.result;
          }
          return null;
        });
    }
  },
  fetchEnergyConsumeLastMutiYear(
    { state, commit, rootState, rootGetters },
    { province, keys, yearStringQuery }
  ) {
    console.log(3, this.state.siteSetting)

    const provinceName = province.name_en;
    const provinceCode = province.iso3166_2.substr(3);

    if (this.state.siteSetting.value === THAI_ENERGY_DATA_ID) {
      return axios
        .get('https://data.energy.go.th/api/ipepp/output/multi-year/energy-consumption', {
          params: {
            keys: keys,
            years: yearStringQuery,
            province_code: provinceCode,
          },
        })
        .then(({data}) => {
          if (!isEmpty(data.result)) {
            return data.result;
          }
          return null;
        });
    } else {
      return axios
        .get('https://pemt-api.mondas.io/system/query', {
          params: {
            api_key: 'ExgWFH9XHHyXIHyKEHIH',
            energyunit: 'ktoe',
            format: 'hash',
            group: ['year', 'key'],
            year: yearStringQuery,
            key: keys,
            province: provinceName, //province.name_en,
          },
        })
        .then(({data}) => {
          if (!isEmpty(data.result)) {
            return data.result;
          }
          return null;
        });
    }
  },
  fetchEnergyConsumeByYear(
    { state, commit, rootState, rootGetters },
    { province, keys, year, isKeyHash = false }
  ) {
    console.log(4, this.state.siteSetting)

    const provinceName = province.name_en;
    const provinceCode = province.iso3166_2.substr(3);

    if (this.state.siteSetting.value === THAI_ENERGY_DATA_ID) {
      return axios
        .get('https://data.energy.go.th/api/ipepp/output/energy-consumption', {
          params: {
            year: year,
            keys: keys,
            province_code: provinceCode,
          },
        })
        .then(({data}) => {
          return data.result;
        });
    } else {
      const params = {
        api_key: 'ExgWFH9XHHyXIHyKEHIH',
        energyunit: 'ktoe',
        year: year,
        key: keys,
        province: provinceName, //province.name_en,
      };
      if (isKeyHash) {
        params.format = 'hash';
        params.group = 'key';
      }

      return axios
        .get('https://pemt-api.mondas.io/system/query', {
          params: params,
        })
        .then(({data}) => {
          return data.result;
        });
    }
  },
  fetchPreElectricConsumeBySector(
    { state, commit, rootState, rootGetters },
    { province, key }
  ) {
    console.log(5, this.state.siteSetting)
    const provinceName = province.name_en;
    const provinceCode = province.iso3166_2.substr(3);

    if (this.state.siteSetting.value === THAI_ENERGY_DATA_ID) {
      return axios
        .get('https://data.energy.go.th/api/ipepp/output/pre-electric-consumption', {
          params: {
            key: key,
            province_code: provinceCode,
          },
        })
        .then(({data}) => {
          if (!isEmpty(data.result)) {
            return data.result;
          }
          return null;
        });
    } else {
      return axios
        .get('https://pemt-api.mondas.io/system/query', {
          params: {
            api_key: 'ExgWFH9XHHyXIHyKEHIH',
            key: key,
            energyunit: 'mwh',
            province: provinceName, //province.name_en,
          },
        })
        .then(({data}) => {
          if (!isEmpty(data.result)) {
            return data.result;
          }
          return null;
        });
    }
  },
  fetchElectricConsumeBySector(
    { state, commit, rootState, rootGetters },
    { year, province }
  ) {
    console.log(6, this.state.siteSetting)

    const provinceName = province.name_en;
    const provinceCode = province.iso3166_2.substr(3);

    if (this.state.siteSetting.value === THAI_ENERGY_DATA_ID) {
      return axios
        .get('https://data.energy.go.th/api/ipepp/output/electric-consumption', {
          params: {
            key: 'RDS_ElCons*',
            year: year,
            province_code: provinceCode,
          },
        })
        .then(({data}) => {
          if (!isEmpty(data.result)) {
            return data.result;
          }
          return null;
        });
    } else {
      return axios
        .get('https://pemt-api.mondas.io/system/query', {
          params: {
            api_key: process.env.VUE_APP_API_KEY,
            format: 'hash',
            group: 'key',
            energyunit: 'mwh',
            key: 'RDS_ElCons*',
            year: year,
            province: provinceName, //province.name_en,
          },
        })
        .then(({data}) => {
          if (!isEmpty(data.result)) {
            return data.result;
          }
          return null;
        });
    }
  },
  fetchProvinceRenewableEnergy(
    { state, commit, rootState, rootGetters },
    { year, province }
  ) {
    console.log(7, this.state.siteSetting)

    const provinceName = province.name_en;
    return axios
      .get('https://pemt-api.mondas.io/system/query', {
        params: {
          api_key: 'ExgWFH9XHHyXIHyKEHIH',
          format: 'hash',
          group: 'key',
          year: '2560', //year,
          key: 'RDS_PVroofPotEx,RDS_BiogasPotEx,RDS_MSWPotEx,RDS_BiomassPotEx,RDS_EthPotEx,BiodieselPotEx',
          province: provinceName, //province.name_en,
        },
      })
      .then(({ data }) => {
        const result = {
          solarRooftop: null,
          biodiesel: null,
          ethanol: null,
          biomass: null,
          msw: null,
          biogas: null,
        };
        if (!isEmpty(data.result)) {
          const solarRooftop = get(data.result, 'RDS_PVroofPotEx.value', null);
          const biodiesel = get(data.result, 'RDS_BiodieselPotEx.value', null);
          const ethanol = get(data.result, 'RDS_EthPotEx.value', null);
          const biomass = get(data.result, 'RDS_BiomassPotEx.value', null);
          const msw = get(data.result, 'RDS_MSWPotEx.value', null);
          const biogas = get(data.result, 'RDS_BiogasPotEx.value', null);

          result.solarRooftop = solarRooftop;
          result.biodiesel = biodiesel;
          result.ethanol = ethanol;
          result.biomass = biomass;
          result.msw = msw;
          result.biogas = biogas;
        }
        return result;
      });
  },
  fetchPowerPlant({ state, commit, rootState, rootGetters }, province) {
    console.log(8, this.state.siteSetting)

    const provinceName = province.name_en;
    const provinceCode = province.iso3166_2.substr(3);

    if (this.state.siteSetting.value === THAI_ENERGY_DATA_ID) {
      return axios
        .get('https://data.energy.go.th/api/ipepp/output/power-plants', {
            params: {
            key: 'RDPP*',
            province_code: provinceCode,
          },
        })
        .then(({ data }) => {
          return data.result;
        });
    } else {
      return axios
        .get('https://pemt-api.mondas.io/system/query', {
          params: {
            api_key: process.env.VUE_APP_API_KEY,
            group: 'key',
            key: 'RDPP*',
            province: provinceName, //province.name_en,
          },
        })
        .then(({ data }) => {
          return data.result;
        });
    }
  },
  fetchOilRefinery({ state, commit, rootState, rootGetters }, province) {
    console.log(9, this.state.siteSetting)

    const provinceName = province.name_en;
    const provinceCode = province.iso3166_2.substr(3);

    if (this.state.siteSetting.value === THAI_ENERGY_DATA_ID) {
      return axios
        .get('https://data.energy.go.th/api/ipepp/output/oil-refineries', {
          params: {
            key: 'RDOR*',
            province_code: provinceCode,
          },
        })
        .then(({ data }) => {
          return data.result;
        });
    } else {
      return axios
        .get('https://pemt-api.mondas.io/system/query', {
          params: {
            api_key: process.env.VUE_APP_API_KEY,
            group: 'key',
            key: 'RDOR*',
            province: provinceName, //province.name_en,
          },
        })
        .then(({data}) => {
          return data.result;
        });
    }
  },
  fetchGasSeperationPlant({ state, commit, rootState, rootGetters }, province) {
    console.log(10, this.state.siteSetting)

    const provinceName = province.name_en;
    const provinceCode = province.iso3166_2.substr(3);

    if (this.state.siteSetting.value === THAI_ENERGY_DATA_ID) {
      return axios
        .get('https://data.energy.go.th/api/ipepp/output/gas-seperation-plants', {
          params: {
            key: 'RDGSP*',
            province_code: provinceCode,
          },
        })
        .then(({ data }) => {
          return data.result;
        });
    } else {
      return axios
        .get('https://pemt-api.mondas.io/system/query', {
          params: {
            api_key: process.env.VUE_APP_API_KEY,
            group: 'key',
            key: 'RDGSP*',
            province: provinceName, //province.name_en,
          },
        })
        .then(({data}) => {
          return data.result;
        });
    }
  },
  fetchIndecesNational(
    { state, commit, rootState, rootGetters },
    { year, key }
  ) {
    console.log(11, this.state.siteSetting)

    if (this.state.siteSetting.value === THAI_ENERGY_DATA_ID) {
      return axios
        .get('https://data.energy.go.th/api/ipepp/output/national-indices', {
          params: {
            key: key,
          },
        })
        .then(({ data }) => {
          return data.result;
        });
    } else {
      return axios
        .get('https://pemt-api.mondas.io/system/query', {
          params: {
            api_key: process.env.VUE_APP_API_KEY,
            key: key,
          },
        })
        .then(({data}) => {
          return data.result;
        });
    }
  },
  fetchIndeces(
    { state, commit, rootState, rootGetters },
    { yearStringQuery, province, key }
  ) {
    console.log(12, this.state.siteSetting)

    const provinceName = province.name_en;
    const provinceCode = province.iso3166_2.substr(3);

    if (this.state.siteSetting.value === THAI_ENERGY_DATA_ID) {
      return axios
        .get('https://data.energy.go.th/api/ipepp/output/multi-year/indices', {
          params: {
            key: key,
            years: yearStringQuery,
            province_code: provinceCode,
          },
        })
        .then(({ data }) => {
          return data.result;
        });
    } else {
      return axios
        .get('https://pemt-api.mondas.io/system/query', {
          params: {
            api_key: process.env.VUE_APP_API_KEY,
            format: 'hash',
            group: 'year',
            year: yearStringQuery,
            key: key,
            province: provinceName, //province.name_en,
          },
        })
        .then(({data}) => {
          return data.result;
        });
    }
  },
  fetchIndecesEnergyConsCom(
    { state, commit, rootState, rootGetters },
    { year, province }
  ) {
    console.log(13, this.state.siteSetting)

    const provinceName = province.name_en;
    const provinceCode = province.iso3166_2.substr(3);
    const yearBackRangeIndex = 4;
    return axios
      .get('https://pemt-api.mondas.io/system/query', {
        params: {
          api_key: process.env.VUE_APP_API_KEY,
          key: 'RDN_EnergyConsCom',
        },
      })
      .then(({ data }) => {
        if (!isEmpty(data.result)) {
          const averageList = data.result;
          // let find min max year of this key
          const generalData = head(averageList);

          let minYear = generalData.min_year;
          let maxYear = generalData.max_year;
          if (minYear && maxYear) {
            let yearInt = parseInt(year);
            if (yearInt < minYear) {
              return null;
            }
            if (yearInt < maxYear) {
              maxYear = yearInt;
            } else {
              yearInt = maxYear;
            }
            if (yearInt - yearBackRangeIndex > minYear) {
              minYear = yearInt - yearBackRangeIndex;
            }
            const yearArray = range(minYear, maxYear + 1);
            const yearStringQuery = yearArray.join(',');

            return axios
              .get('https://pemt-api.mondas.io/system/query', {
                params: {
                  api_key: process.env.VUE_APP_API_KEY,
                  format: 'hash',
                  group: 'year',
                  year: yearStringQuery,
                  key: 'RDI_EnergyConsCom',
                  province: provinceName, //province.name_en,
                },
              })
              .then(({ data }) => {
                let titleIndex = get(generalData, 'index', null);
                if (!titleIndex) {
                  titleIndex = get(generalData, 'index`', 'หัวข้อ');
                }
                const title =
                  titleIndex + ' ' + generalData.lists + ' ' + generalData.unit;
                const unit = generalData.unit;
                const plotdata = [
                  ['Year', generalData.lists, 'ค่าเฉลี่ยระดับประเทศ'],
                ];
                if (!isEmpty(data.result)) {
                  forIn(data.result, (item, key) => {
                    const average = find(averageList, { year: key }).value;
                    plotdata.push([key, item.value, average]);
                  });
                }
                const result = {
                  title: title,
                  unit: unit,
                  data: plotdata,
                };
                return result;
              });
          } else {
            return null;
          }
        }
        return null;
      });
  },
  fetchPreREEEPotential(
    { state, commit, rootState, rootGetters },
    { province, key }
  ) {
    console.log(14, this.state.siteSetting)

    const provinceName = province.name_en;
    const provinceCode = province.iso3166_2.substr(3);

    if (this.state.siteSetting.value === THAI_ENERGY_DATA_ID) {
      return axios
        .get('https://data.energy.go.th/api/ipepp/output/pre-potential', {
          params: {
            key: key,
            province_code: provinceCode,
          },
        })
        .then(({data}) => {
          if (!isEmpty(data.result)) {
            return data.result;
          }
          return null;
        });
    } else {
      return axios
        .get('https://pemt-api.mondas.io/system/query', {
          params: {
            api_key: 'ExgWFH9XHHyXIHyKEHIH',
            key: key,
            province: provinceName, //province.name_en,
          },
        })
        .then(({data}) => {
          if (!isEmpty(data.result)) {
            return data.result;
          }
          return null;
        });
    }
  },
  fetchREEEPotential(
    { state, commit, rootState, rootGetters },
    { year, province }
  ) {
    console.log(15, this.state.siteSetting)

    const provinceName = province.name_en;
    const provinceCode = province.iso3166_2.substr(3);

    if (this.state.siteSetting.value === THAI_ENERGY_DATA_ID) {
      return axios
        .get('https://data.energy.go.th/api/ipepp/output/potential', {
          params: {
            key: 'RDREEEP_*',
            year: year,
            province_code: provinceCode,
          },
        })
        .then(({data}) => {
          if (!isEmpty(data.result)) {
            return data.result;
          }
          return null;
        });
    } else {
      return axios
        .get('https://pemt-api.mondas.io/system/query', {
          params: {
            api_key: 'ExgWFH9XHHyXIHyKEHIH',
            format: 'hash',
            group: 'key',
            year: year, //year,
            key: 'RDREEEP_*',
            province: provinceName, //province.name_en,
          },
        })
        .then(({data}) => {
          if (!isEmpty(data.result)) {
            return data.result;
          }
          return null;
        });
    }
  },
  // https://pemt-api.mondas.io/system/query?format=hash&group=key&key=RDREEEP*&province=Rayong&api_key=ExgWFH9XHHyXIHyKEHIH
  fetchScenarioOverview1({ state, commit, rootState, rootGetters }, province) {
    console.log(16, this.state.siteSetting)

    const provinceName = province.name_en;
    const provinceCode = province.iso3166_2.substr(3);
    return axios
      .get('https://pemt-api.mondas.io/chart/overview1', {
        params: {
          api_key: 'ExgWFH9XHHyXIHyKEHIH',
          province: provinceName, //province.name_en,
        },
      })
      .then(({ data }) => {
        if (!isEmpty(data)) {
          return data;
        }
        return null;
      });
  },
  fetchScenarioOverview2({ state, commit, rootState, rootGetters }, province) {
    console.log(17, this.state.siteSetting)

    const provinceName = province.name_en;
    const provinceCode = province.iso3166_2.substr(3);
    return axios
      .get('https://pemt-api.mondas.io/chart/overview2', {
        params: {
          api_key: 'ExgWFH9XHHyXIHyKEHIH',
          province: provinceName, //province.name_en,
        },
      })
      .then(({ data }) => {
        if (!isEmpty(data)) {
          return data;
        }
        return null;
      });
  },
  fetchScenarioOverview3({ state, commit, rootState, rootGetters }, province) {
    console.log(18, this.state.siteSetting)

    const provinceName = province.name_en;
    const provinceCode = province.iso3166_2.substr(3);
    return axios
      .get('https://pemt-api.mondas.io/chart/overview3', {
        params: {
          api_key: 'ExgWFH9XHHyXIHyKEHIH',
          province: provinceName, //province.name_en,
        },
      })
      .then(({ data }) => {
        if (!isEmpty(data)) {
          return data;
        }
        return null;
      });
  },
  fetchScenarioEnergyDemand(
    { state, commit, rootState, rootGetters },
    { province, group }
  ) {
    console.log(19, this.state.siteSetting)

    const provinceName = province.name_en;
    const provinceCode = province.iso3166_2.substr(3);
    return axios
      .get('https://pemt-api.mondas.io/chart/demand', {
        params: {
          api_key: 'ExgWFH9XHHyXIHyKEHIH',
          province: provinceName, //province.name_en,
          group: group, // BAU, EE, EEPLUS,
        },
      })
      .then(({ data }) => {
        if (!isEmpty(data)) {
          return data;
        }
        return null;
      });
  },
  fetchScenarioEnergySupply1(
    { state, commit, rootState, rootGetters },
    { province, group }
  ) {
    console.log(20, this.state.siteSetting)

    const provinceName = province.name_en;
    const provinceCode = province.iso3166_2.substr(3);
    return axios
      .get('https://pemt-api.mondas.io/chart/supply1', {
        params: {
          api_key: 'ExgWFH9XHHyXIHyKEHIH',
          province: provinceName, //province.name_en,
          group: group, // BAU, EE, EEPLUS,
        },
      })
      .then(({ data }) => {
        if (!isEmpty(data)) {
          return data;
        }
        return null;
      });
  },
  fetchScenarioEnergySupply2(
    { state, commit, rootState, rootGetters },
    { province, group }
  ) {
    console.log(21, this.state.siteSetting)

    const provinceName = province.name_en;
    const provinceCode = province.iso3166_2.substr(3);
    return axios
      .get('https://pemt-api.mondas.io/chart/supply2', {
        params: {
          api_key: 'ExgWFH9XHHyXIHyKEHIH',
          province: provinceName, //province.name_en,
          group: group, // BAU, EE, EEPLUS,
        },
      })
      .then(({ data }) => {
        if (!isEmpty(data)) {
          return data;
        }
        return null;
      });
  },
  fetchScenarioEnergySupply3(
    { state, commit, rootState, rootGetters },
    { province, group }
  ) {
    console.log(22, this.state.siteSetting)

    const provinceName = province.name_en;
    const provinceCode = province.iso3166_2.substr(3);
    return axios
      .get('https://pemt-api.mondas.io/chart/supply3', {
        params: {
          api_key: 'ExgWFH9XHHyXIHyKEHIH',
          province: provinceName, //province.name_en,
          group: group, // BAU, EE, EEPLUS,
        },
      })
      .then(({ data }) => {
        if (!isEmpty(data)) {
          return data;
        }
        return null;
      });
  },
  fetchScenarioSankey(
    { state, commit, rootState, rootGetters },
    { province, scenario }
  ) {
    console.log(23, this.state.siteSetting)

    const provinceName = province.name_en;
    const provinceCode = province.iso3166_2.substr(3);
    return axios
      .get('https://pemt-api.mondas.io/sankey/future', {
        params: {
          api_key: 'ExgWFH9XHHyXIHyKEHIH',
          province: provinceName, //province.name_en,
          scenario: scenario, // P001, P002, ...
          type: 'overview', // BAU, EE, EEPLUS,
        },
      })
      .then(({ data }) => {
        if (!isEmpty(data)) {
          return data;
        }
        return null;
      });
  },
};
