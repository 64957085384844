import axios from 'axios';
import { assign, map, keyBy, cloneDeep, mapValues } from 'lodash';
import Vue from 'vue';

export const state = {
  topicSelectedIndex: 0,
  topics: [],
  reports: {},
  reportEvaluations: {},
  topicProvinceReportEvaluations: {},
  provinceReportEvaluations: {},
};

export const getters = {
  currentTopic(state) {
    return state.topics[state.topicSelectedIndex];
  },
  getCurrentTopicProvinceReportEvaluation(state, getters) {
    if (!getters.currentTopic) {
      return null;
    }
    return state.topicProvinceReportEvaluations[getters.currentTopic.id];
  },
  getReport: (state, getters) => (province) => {
    //nullable
    const reportId = (
      (getters.getCurrentTopicProvinceReportEvaluation || [])[province.id] || []
    ).report;
    if (reportId) {
      return state.reports[reportId];
    }
    return null;
  },
  getReportEvaluation: (state, getters) => (province) => {
    //nullable
    const reportEvaluationId = (
      (getters.getCurrentTopicProvinceReportEvaluation || [])[province.id] || []
    ).reportEvaluation;
    if (reportEvaluationId) {
      return state.reportEvaluations[reportEvaluationId];
    }
    return null;
  },
  getReportEvaluationScore: (state, getters) => (province) => {
    const reportEvaluationId = (
      (getters.getCurrentTopicProvinceReportEvaluation || [])[province.id] || []
    ).reportEvaluation;
    if (reportEvaluationId) {
      return (state.reportEvaluations[reportEvaluationId] || []).total_score;
    }
    return null;
  },
};

export const mutations = {
  setTopicSelectedIndex(state, topicSelectedIndex) {
    state.topicSelectedIndex = topicSelectedIndex;
  },
  setTopics(state, topics) {
    state.topics = topics;
    topics.forEach((item) => {
      if (!state.topicProvinceReportEvaluations[item.id]) {
        state.topicProvinceReportEvaluations[item.id] = {};
      }
    });
  },
  setTopicProvinceReportEvaluations(state, { topic, topicReportEvaluations }) {
    topicReportEvaluations.reports.forEach((item) => {
      if (!state.topicProvinceReportEvaluations[topic.id][item.province_id]) {
        state.topicProvinceReportEvaluations[topic.id][item.province_id] = {};
      }
      state.topicProvinceReportEvaluations[topic.id][item.province_id].report =
        item.id;
    });
    topicReportEvaluations.reportEvaluations.forEach((item) => {
      if (!state.topicProvinceReportEvaluations[topic.id][item.province_id]) {
        state.topicProvinceReportEvaluations[topic.id][item.province_id] = {};
      }
      state.topicProvinceReportEvaluations[topic.id][
        item.province_id
      ].reportEvaluation = item.id;
    });
    assign(state.reports, keyBy(topicReportEvaluations.reports, 'id'));
    assign(
      state.reportEvaluations,
      keyBy(topicReportEvaluations.reportEvaluations, 'id')
    );
  },
  setReportEvaluation(state, { topic, province, reportEvaluation }) {
    Vue.set(
      state.topicProvinceReportEvaluations[topic.id][province.id],
      'reportEvaluation',
      reportEvaluation.id
    );
    Vue.set(state.reportEvaluations, reportEvaluation.id, reportEvaluation);
    state.reportEvaluations = assign(
      {},
      mapValues(state.reportEvaluations, (item) => {
        if (item.id === reportEvaluation.id) {
          return reportEvaluation;
        }
        return item;
      })
    );
  },
  setProvinceReportEvaluations(state, { province, provinceReportEvaluations }) {
    state.provinceReportEvaluations[province.id] = provinceReportEvaluations;
  },
};

export const actions = {
  fetchTopics({ state, commit, rootState, rootGetters }, nationalPlan) {
    return axios
      .get('/api/national-plans/' + nationalPlan.id + '/evaluation_topics')
      .then(({ data }) => {
        commit('setTopics', data);
        return data;
      });
  },
  fetchTopicReportEvaluations({ state, commit, rootState }, topic) {
    return axios
      .get('/api/evaluation_topics/' + topic.id + '/report_evaluations')
      .then(({ data }) => {
        commit('setTopicProvinceReportEvaluations', {
          topic: topic,
          topicReportEvaluations: data,
        });
        return data;
      });
  },
  fetchReportEvaluationAnswer(
    { state, commit, rootState },
    { topic, province }
  ) {
    return axios
      .get('/api/evaluation_topics/' + topic.id + '/answer/' + province.id)
      .then(({ data }) => {
        if (data && Object.keys(data).length > 0) {
          commit('setReportEvaluation', {
            topic: topic,
            province: province,
            reportEvaluation: data,
          });
          return cloneDeep(data);
        }
        return null;
      });
  },
  evaluate(
    { state, commit, rootState },
    { topic, province, defect_answer, note }
  ) {
    return axios
      .post('/api/evaluation_topics/' + topic.id + '/evaluate/' + province.id, {
        defect_answer: defect_answer,
        note: note,
      })
      .then(({ data }) => {
        commit('setReportEvaluation', {
          topic: topic,
          province: province,
          reportEvaluation: data,
        });
        return data;
      });
  },
  fetchProvinceReportEvaluations(
    { state, commit, rootState },
    { nationalPlan, province }
  ) {
    return axios
      .get(
        '/api/national-plans/' +
          nationalPlan.id +
          '/province/' +
          province.id +
          '/report_evaluations'
      )
      .then(({ data }) => {
        commit('setProvinceReportEvaluations', {
          province: province,
          provinceReportEvaluations: data.reportEvaluations,
          reportTotalScore: data.report_total_score,
        });
        return data;
      });
  },
};
