import Vue from 'vue';

const Config = {
  autoNumericOption: function (decimalPlace) {
    return {
      modifyValueOnWheel: false,
      decimalPlaces: decimalPlace,
      digitGroupSeparator: ',',
      decimalCharacter: '.',
      decimalCharacterAlternative: '.',
    };
  },
  prettyUnit: function (unit) {
    switch (unit) {
      case 'mwh':
        return 'MWh';
      default:
        return unit;
    }
  },
};

Vue.prototype.$config = Config;

export default Config;
