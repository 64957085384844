/**
 * v-dialogs
 */
import Vue from 'vue';
import vDialog from '../../components/vDialog/vDialogContainer.vue';

let Dialog = Vue.component(vDialog.name, vDialog);
let dlg = new Dialog();
let vm = dlg.$mount();
document.querySelector('body').appendChild(vm.$el);

//dlg.$mount();
Vue.prototype.$vDialog = {
  alert(msg, callback, params = {}) {
    if (!msg) return;
    params.message = msg;
    params.callback = callback;
    return dlg.addAlert(params);
  },
  close(data, key) {
    dlg.close(data, key);
  },
  closeAll(callback) {
    dlg.closeAll(callback);
  },
};
