module.exports = {
  en: {},
  th: {
    'Natural Gas': 'ก๊าซธรรมชาติ',
    Coal: 'ถ่านหิน',
    Solar: 'พลังงานแสงอาทิตย์',
    Biogas: 'พลังงานชีวภาพ',
    Biomass: 'พลังงานชีวมวล',
    'Fuel oil/ Plam oil': 'น้ำมันเตา/ น้ำมันปาล์ม',
    'Fuel oil': 'น้ำมันเตา',
    'Plam oil': 'น้ำมันปาล์ม',
    PSO: 'กยผ',
  },
};
