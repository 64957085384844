<template>
  <section class="loading">
    <vue-element-loading
      :active="!isInitialised"
      spinner="bar-fade-scale"
      :is-full-screen="true"
    />
  </section>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  name: 'Loading',

  data: () => ({}),

  computed: {
    ...mapState(['isInitialised', 'intendedRoute']),
  },

  watch: {
    isInitialised() {
      if (this.isInitialised) {
        this.enterApp();
      }
    },
  },

  created() {
    this.initialise();
  },

  methods: {
    ...mapMutations(['hideLoading', 'setIntendedRoute']),
    ...mapActions(['initialise']),
    enterApp() {
      this.route();
      this.hideLoading();
    },
    route() {
      if (this.intendedRoute) {
        if (this.intendedRoute.name) {
          this.$router.push({
            name: this.intendedRoute.name,
            params: this.intendedRoute.params,
            query: this.intendedRoute.query,
          });
        } else {
          this.$router.push({ name: 'home' });
        }
        this.setIntendedRoute(null);
      } else if (this.$route.meta.guest) {
        this.$router.push({ path: '/' });
      }
    },
  },
};
</script>

<style>
.title.inspiring {
  font-weight: 200;
}
</style>
