module.exports = {
  en: {
    common: {
      error: 'Error has occurred. Please try again.',
      retry: 'retry',
      save: 'save',
    },
  },
  th: {
    common: {
      error: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง',
      retry: 'ลองอีกครั้ง',
      save: 'บันทึก',
    },
  },
};
