<template>
  <div
    class="
      text-center
      d-flex
      flex-row
      justify-content-center
      align-items-center
      h-75
    "
  >
    <h2>403 Unauthorized</h2>
  </div>
</template>

<script>
export default {
  name: 'unauthorized',
};
</script>

<style scoped></style>
