import store from '../store';
import router from '../router';
import progressBar from './before/progressBar';
import allow from './before/allow';
import afterProgressBar from './after/progressBar';

export default (to, from, next) => {
  progressBar();
  if (store.state.auth.isAuth) {
    if (to.meta.guest) {
      next({ name: 'home' });
    } else if (to.name == 'home') {
      if (store.getters.isPeno) {
        next({ name: 'peno.overview.intro' });
      } else if (store.getters.isPso) {
        next({ name: 'pso.overview' });
      } else if (store.getters.isAdmin) {
        next({ name: 'admin.national_plan_settings' });
      } else {
        allow(to, from, next);
      }
    } else {
      allow(to, from, next);
    }
  } else {
    if (to.meta.guest) {
      next();
    } else {
      store.commit('setIntendedRoute', to);
      if (from.name == 'login') {
        afterProgressBar();
      }
      next({ name: 'login' });
    }
  }
};
