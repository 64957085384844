(function ($) {
  'use strict';

  $.extend(true, $.trumbowyg, {
    langs: {
      // jshint camelcase:false
      en: {
        smaller: 'Smaller',
      },
    },
  });
  // jshint camelcase:true

  var defaultOptions = {};

  // Add dropdown with font sizes
  $.extend(true, $.trumbowyg, {
    plugins: {
      smaller: {
        init: function (trumbowyg) {
          trumbowyg.addBtnDef('smaller', buildButtonDef(trumbowyg));
        },
      },
    },
  });

  // If the plugin is a button
  function buildButtonDef(trumbowyg) {
    return {
      fn: function () {
        trumbowyg.saveRange();
        var text = trumbowyg.getRangeText();
        console.log(trumbowyg.range);
        console.log(typeof trumbowyg.range.startContainer);
        trumbowyg.range.deleteContents();
        trumbowyg.range.insertNode(
          $(`<span class="smaller">${text}</span>`)[0]
        );
        trumbowyg.syncCode();
        trumbowyg.$c.trigger('tbwchange');
        // if (text.replace(/\s/g, '') !== '') {
        //     try {
        //         var parent = getSelectionParentElement();
        //         $(parent).toggleClass('smaller');
        //     } catch (e) {
        //     }
        // }
      },
    };
  }

  // Get the selection's parent
  function getSelectionParentElement() {
    var parentEl = null,
      selection;
    if (window.getSelection) {
      selection = window.getSelection();
      if (selection.rangeCount) {
        parentEl = selection.getRangeAt(0).commonAncestorContainer;
        if (parentEl.nodeType !== 1) {
          parentEl = parentEl.parentNode;
        }
      }
    } else if (
      (selection = document.selection) &&
      selection.type !== 'Control'
    ) {
      parentEl = selection.createRange().parentElement();
    }
    return parentEl;
  }
})(jQuery);
