<template>
  <div v-show="dialogs.length" class="vDialogContainer">
    <!--<transition-group name="animated" enter-class="bounce" enter-active-class="bounce">-->
    <!--<transition enter-class="vDialogOpen">-->
    <vDialog
      is="vDialog"
      v-for="(dlg, index) in dialogs"
      :setting="dlg"
      :dialog-index="index"
      :key="dlg.dialogKey"
      :dialog-key="dlg.dialogKey"
      @close="closeDialog"
    ></vDialog>
    <!--</transition>-->
    <!--</transition-group>-->
  </div>
</template>

<script>
import vDialog from './vDialog';
import constant from './vDialogConstants';
let {
  dialogDefaults,
  messageTypes,
  alertIconClass,
  toastConstants,
  languages,
} = constant;
export default {
  name: 'VDialogs',
  components: {
    vDialog,
  },
  data() {
    return {
      dialogs: [],
      keyPrefix: 'v-dialogs-',
      keyNum: 0,
    };
  },
  methods: {
    /**
     * Merge user options and default options
     * @param config - user options
     * @return merged options
     */
    buildDialogConfig(config) {
      let merged = Object.assign({}, dialogDefaults, config);
      return merged;
    },
    /**
     * String cut
     * @param str [string] src string
     * @param n   [number] save string length
     * @returns string
     */
    stringSub(str, n) {
      if (typeof str !== 'string') return;
      let r = /[^\x00-\xff]/g;
      if (str.replace(r, 'mm').length <= n) {
        return str;
      }
      let m = Math.floor(n / 2);
      for (let i = m; i < str.length; i++) {
        if (str.substr(0, i).replace(r, 'mm').length >= n) {
          return str.substr(0, i) + '...';
        }
      }
      return str;
    },
    /**
     * Init default options
     */
    buildDialog(config) {
      let idx = this.dialogs.findIndex(
        (val) => config.singletonKey && val.singletonKey === config.singletonKey
      );
      if (idx === -1) {
        this.keyNum++;
        let key = this.keyPrefix + this.keyNum;
        config.dialogKey = key;
        this.dialogs.push(config);
        return key;
      } else return null;
    },
    /**
     * Open a Modal dialog
     * @param p - options
     */
    addModal(p) {
      let config = this.buildDialogConfig(p);
      config.type = 'modal';
      return this.buildDialog(config);
    },
    /**
     * Open a message alert dialog, types including info, warning, error, success, confirm
     * @param p - options
     */
    addAlert(p) {
      let config = this.buildDialogConfig(p);

      config.type = 'alert';
      config.i18n = languages[config.language];
      let title = config.i18n.titleInfo;
      switch (config.messageType) {
        case messageTypes.warning:
          title = config.i18n.titleWarning;
          break;
        case messageTypes.error:
          title = config.i18n.titleError;
          break;
        case messageTypes.success:
          title = config.i18n.titleSuccess;
          break;
        case messageTypes.confirm:
          title = config.i18n.titleConfirm;
          break;
      }
      config.title = title;
      config.dialogCloseButton = false;
      config.dialogMaxButton = false;

      return this.buildDialog(config);
    },
    /**
     * Open a full screen mask
     * @param p - options
     */
    addMask(p) {
      let config = this.buildDialogConfig(p);
      config.type = 'mask';
      config.i18n = languages[config.language];
      config.message = config.message || config.i18n.maskText;
      config.message = this.stringSub(config.message, 65);
      config.title = false;

      return this.buildDialog(config);
    },
    /**
     * Close dialog, last one or specified key dialog (Modal, Alert, Mask, Toast)
     * @param data - the data return to caller
     * @param key - the dialog key, you can get it like this: let key = this.$vDialog.alert('your msg');
     */
    close(data, key) {
      if (this.dialogs.length === 0) return;
      let idx = -1,
        dKey = key,
        dlg;

      if (!key) {
        dKey = this.dialogs[this.dialogs.length - 1].dialogKey;
      }

      if (typeof data !== 'undefined') {
        this.dialogs.find((val) => val.dialogKey === dKey).returnData = data;
      }
      this.closeDialog(dKey);
    },
    /**
     * Close dialog (remove dialogs array item) and call user callback function
     * @param key - dialog key
     */
    closeDialog(key) {
      if (!key) return;
      let dlg = this.dialogs.find((val) => val.dialogKey === key);
      if (dlg) {
        this.dialogs = this.dialogs.filter((val) => val.dialogKey !== key);
        this.$nextTick(() => {
          if (dlg.callback && typeof dlg.callback === 'function' && !dlg.cancel)
            dlg.callback(dlg.returnData);
          if (
            dlg.cancel &&
            dlg.cancelCallback &&
            typeof dlg.cancelCallback === 'function'
          )
            dlg.cancelCallback();
        });
      }
    },
    /**
     * Close all dialog
     */
    closeAll(callback) {
      if (this.dialogs.length) {
        this.dialogs.splice(0, this.dialogs.length);
        this.$nextTick(() => {
          if (callback && typeof callback === 'function') callback();
        });
      }
    },
  },
};
</script>

<style scoped>
div.vDialogContainer {
  width: 0;
  height: 0;
  position: fixed;
  z-index: 1030;
  font-family: 'PSLxChamnarn', 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
