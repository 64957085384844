export const state = {
  isAuth: localStorage.getItem('isAuth') === 'true',
  isImpersonating: localStorage.getItem('isImpersonating') === 'true',
  impersonatingFrom: localStorage.getItem('impersonatingFrom'),
};

export const getters = {
  isImpersonating(state) {
    return state.isImpersonating;
  },
  impersonatingFrom(state) {
    return state.impersonatingFrom;
  },
};

export const actions = {
  setImpersonate({state, commit}, impersonate) {
    commit('setImpersonate', impersonate);
  },
  setImpersonatingFrom({state, commit}, pageName) {
    commit('setImpersonatingFrom', pageName);
  },
};

export const mutations = {
  login(state) {
    state.isAuth = true;
    localStorage.setItem('isAuth', true);
  },
  logout(state) {
    state.isAuth = false;
    state.isImpersonating = false;
    localStorage.setItem('isAuth', false);
    localStorage.setItem('isImpersonating', false);
  },
  setImpersonate(state, impersonate) {
    state.isImpersonating = impersonate;
    localStorage.setItem('isImpersonating', impersonate);
  },
  setImpersonatingFrom(state, pageName) {
    state.impersonatingFrom = pageName;
    if (pageName) {
      localStorage.setItem('impersonatingFrom', pageName);
    } else {
      localStorage.removeItem('impersonatingFrom');
    }
  },
};
