import Vue from 'vue';
import { get, set, forIn, isArray, isString } from 'lodash';

export default class Errors {
  /**
   * Create a new Errors instance.
   */
  constructor() {
    this.errors = {};
  }

  /**
   * Determine if an errors exists for the given field.
   *
   * @param {string} field
   */
  has(field) {
    return this.errors.hasOwnProperty(field);
    // return Object.prototype.hasOwnProperty.call(this.errors, field);
    // return window._.has(this.errors, field);
  }

  /**
   * Determine if we have any errors.
   */
  any() {
    return Object.keys(this.errors).length > 0;
  }

  /**
   * Retrieve the error message for a field.
   *
   * @param {string} field
   */
  get(field) {
    if (this.errors[field]) {
      if (isString(this.errors[field][0])) {
        return this.errors[field][0];
      }
      return this.errors[field];
    }
    // return null;
    /* const errorsArr = window._.get(this.errors, field);
    if (errorsArr) {
      if (errorsArr[0]) {
        return errorsArr[0];
      }
      return window._.cloneDeep(errorsArr);
    } */
    return null;
  }

  /**
   * Set the error messages for a field.
   *
   * @param {string} field
   * @param {array} value
   */
  set(field, value) {
    Vue.set(this.errors, field, [value]);
    // this.errors[field] = value;
  }

  /**
   * Record the new errors.
   *
   * @param {object} errors
   */
  record(errors) {
    this.errors = {};
    const errorOfArrayFields = {};
    forIn(errors, (item, key) => {
      if (/.+\.[0-9]+.*/g.test(key)) {
        const split = key.split('.');
        set(errorOfArrayFields, split, item.join(', '));
      } else {
        Vue.set(this.errors, key, item);
      }
    });
    forIn(errorOfArrayFields, (item, key) => {
      Vue.set(this.errors, key, item);
    });
  }

  /**
   * Clear one or all error fields.
   *
   * @param {string|null} field
   */
  clear(field) {
    if (field) {
      const lastDot = field.lastIndexOf('.');
      if (lastDot !== -1) {
        const parentField = field.substring(0, lastDot);
        const deepestField = field.substring(lastDot + 1);
        const parent = get(this.errors, parentField);
        if (parent) {
          Vue.delete(parent, deepestField);
          if (Object.keys(parent).length === 0) {
            this.clear(parentField);
          }
        }
      } else {
        Vue.delete(this.errors, field);
      }
      return;
    }

    this.errors = {};
  }
}
