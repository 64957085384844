module.exports = {
  en: {
    unit: {
      baht: 'Baht',
      million_baht: 'Million Baht',
    },
  },
  th: {
    unit: {
      baht: 'บาท',
      million_baht: 'ล้านบาท',
    },
  },
};
