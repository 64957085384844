const Dashboard = () => import('../views/layouts/PsoDashboard.vue');
const Overview = () => import('../views/pso/Overview.vue');
const ProvinceSummary = () => import('../views/pso/ProvinceSummary.vue');
const ProvinceEnergyReport = () =>
  import('../views/pso/ProvinceEnergyReport.vue');
const Evaluation = () => import('../views/pso/Evaluation.vue');
const NationalPlan = () => import('../views/pso/NationalPlan.vue');
const Announcement = () => import('../views/pso/Announcement.vue');
const TargetGuideline = () => import('../views/pso/TargetGuideline.vue');

export default [
  {
    path: '/pso',
    component: Dashboard,
    meta: {
      deniedRoles: ['peno'],
    },
    children: [
      {
        path: 'overview',
        name: 'pso.overview',
        component: Overview,
      },
      {
        path: 'province_summary',
        name: 'pso.province_summary',
        component: ProvinceSummary,
      },
      {
        path: 'evaluation',
        name: 'pso.evaluation',
        component: Evaluation,
      },
      {
        path: 'national_plan',
        name: 'pso.national_plan',
        component: NationalPlan,
      },
      {
        path: 'announcement',
        name: 'pso.announcement',
        component: Announcement,
      },
      {
        path: 'target-guideline',
        name: 'pso.target_guideline',
        component: TargetGuideline,
      },
    ],
  },
  {
    path: '/pso/province-energy-report/:year/:iso3166_2',
    name: 'pso.energy_report',
    component: ProvinceEnergyReport,
    meta: {
      deniedRoles: ['peno'],
    },
  },
];
